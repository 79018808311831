import styled from 'styled-components';

import CheckMark from 'assets/img/check-mark.svg';
import Key from 'assets/img/key-icon.svg';

const Background = styled.div`
  ${({ img }) =>
    img &&
    `
    background: url(${img}) no-repeat center center fixed;
    background-size: cover;
  `}
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  @media (min-width: 769px) {
    background: #eeeeee;
  }
`;

const Wrapper = styled.div`
  max-width: 1016px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
`;

const LeftColumn = styled.div`
  display: none;
  flex: 1 1 50%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  @media (min-width: 769px) {
    display: unset;
  }
`;

const RightColumn = styled.div`
  flex: 1 1 50%;
  padding: 48px 36px;

  @media (max-width: 769px) {
    padding: 48px 20px;
  }
`;

const AuthInput = styled.div`
  position: relative;
  height: 48px;
  width: 100%;

  .form__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 40px 5px 12px;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    background: transparent;

    &:focus {
      border-color: #fcc900;
    }

    &:focus ~ .form__label,
    &.label-top ~ .form__label {
      top: 10px;
      font-size: 10px;
    }
  }

  .form__label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    font-size: 12px;
    color: #bdbdbd;
    pointer-events: none;
  }

  .toggle-password {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #bdbdbd;
  }
`;

const AuthButton = styled.button`
  height: 48px;
  width: 100%;
  background-color: hsl(48, 100%, 49%);
  border: 1px solid #fcc900;
  border-radius: 8px;
  color: #1a1a1a;
  font: 600 16px/35px Poppins;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: hsl(48, 100%, 46%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${props => props.size};
  height: ${props => props.size};

  & .circle {
    stroke: black;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const AuthHeader = styled.h1`
  font: normal 700 28px/35px Poppins;
  color: #1a1a1a;
  margin: 0 0 10px 0;
`;

const AuthParagraph = styled.p`
  font: normal 400 12px/18px Poppins;
  color: #1a1a1a;

  .email {
    font-weight: 600;

    &:before {
      content: '\\a';
      white-space: pre;
    }
  }

  ${({ icon }) =>
    icon &&
    `
    padding-left: 20px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 19px;
        width: 10px;
        background: url(${Key}) no-repeat;
        background-size: 10px 19px;
      }
  `}
`;

const AuthCheckboxWrapper = styled.div`
  p {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 5px;
    padding-left: 16px;

    &.isValid {
      color: #16a94d;
      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 2px;
        height: 9px;
        width: 12px;
        background-image: url(${CheckMark});
        background-size: 12px 9px;
      }
    }

    &.isInvalid {
      color: #cc0000;
    }
  }
`;

const AuthForm = styled.form`
  margin-top: 40px;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ changePassword }) =>
    changePassword &&
    `
    > *:first-child,
    > *:nth-child(2) {
      margin-bottom: 5px;
    }
  `}
`;

const AuthLink = styled.p`
  cursor: pointer;
  font: normal 400 12px/18px Poppins;
  color: #1a1a1a;
  display: inline-block;

  &:hover {
    color: hsl(48, 100%, 46%);
  }
`;

const ResetPasswordInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  * {
    text-align: center;

    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-top: 40px;
      margin-bottom: auto;
    }
  }
`;

const ErrorMessage = styled.p`
  font: normal 400 12px/18px Poppins;
  color: #cc0000;

  span {
    color: #1a1a1a;

    &:before {
      content: '\\a';
      white-space: pre;
    }
  }
`;

const AccLock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    font: normal 400 12px/18px Poppins;
    color: #1a1a1a;

    &:first-child {
      margin-top: auto;
    }

    &:nth-child(2) {
      color: #cc0000;
    }

    &:last-child {
      margin-bottom: auto;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const S = {
  Background,
  AuthInput,
  Wrapper,
  LeftColumn,
  RightColumn,
  AuthButton,
  AuthHeader,
  AuthParagraph,
  AuthCheckboxWrapper,
  AuthForm,
  AuthLink,
  ResetPasswordInfoWrapper,
  ErrorMessage,
  AccLock,
  StyledSpinner,
};

export default S;
