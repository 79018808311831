import _ from 'lodash';

import {
  fetchIngredient,
  fetchIngredientCategories,
} from 'actions/Ingredients';
import { fetchAllergens } from 'actions/Allergens';
import { fetchUnits } from 'actions/Units';
import { fetchWholesalers } from 'actions/Wholesalers';

import { post, put } from 'helpers/apiHelpers';
import {
  countBeforeDecimal,
  countDecimal,
  toDecimalPlaces,
} from 'helpers/helpers';

import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import FormSelectMultiple from 'components/FormSelect/FormSelectMultiple';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import defaultState from '../defaultState';

import Danger from 'components/Typography/Danger';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { get } from 'helpers/apiHelpers';
import { Trans } from 'react-i18next';

import { useState } from 'react';
import { useEffect } from 'react';

import FormIngredientsView from './FormIngredients.view';
import { mapApiResponseToStateObject } from './MapApiResponseToStateObject';
import { toast } from 'react-toastify';

const FormIngredientsContainer = ({
  fetchAllergens,
  fetchUnits,
  fetchIngredientCategories,
  fetchWholesalers,
  match,
  location,
  t,
  history,
  wholesalers,
  ingredientCategories,
  units,
  allergens,
  currency,
  classes,
}) => {
  const [state, setState] = useState({
    ...defaultState,
    edit: match.path.includes('edit'),
    isLoading: false,
    contractors: [],
    contractor: null,
    defaultLanguage: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    Promise.all([
      fetchAllergens(),
      fetchUnits(),
      fetchIngredientCategories(),
      fetchWholesalers(),
      get('/contractors', { pagination: false }),
    ]).then(res => {
      const { 'hydra:member': contractors } = res[4];

      if (state.edit) {
        get(`/ingredients/${match.params.id}`, { translations: true }).then(
          res => {
            loadIngredient(res);
          }
        );
      }
      const index = location.search.indexOf('/') + 1;
      const length = location.search.length;
      location.search.includes('fromSavedIngredient') &&
        get(`/ingredients/${location.search.slice(index, length)}`, {
          translations: true,
        }).then(res => {
          loadIngredient(res);
        });

      setState(prev => ({
        ...prev,
        isLoading: true,
        contractors: [
          {
            '@id': null,
            name: t('form.field.contractor.selectOption.noContractor'),
          },
          ...contractors,
        ],
      }));
      setIsLoading(true);
    });
  }, []);

  const handleToggle = propertyName => {
    setState(prev => ({ ...prev, [propertyName]: !state[propertyName] }));
  };

  const getCaloriesSumByWeight = () => {
    const elementsWeights = {
      proteins: 4,
      carbohydrates: 4,
      fat: 9,
      dietaryFiber: 2,
    };

    const computeElementWeight = element => {
      return (+state[element] || 0) * elementsWeights[element];
    };

    const elementsToSum = ['proteins', 'carbohydrates', 'fat'];

    let caloriesSumByWeight = 0;

    elementsToSum.forEach(
      element => (caloriesSumByWeight += computeElementWeight(element))
    );

    return caloriesSumByWeight;
  };

  const openConfirmNestedUpdatesModal = () =>
    setState(prevState => ({
      ...prevState,
      confirmNestedUpdatesModal: true,
    }));

  const closeConfirmNestedUpdatesModal = () =>
    setState(prevState => ({
      ...prevState,
      confirmNestedUpdatesModal: false,
    }));

  const caloriesErrorDetector = () => {
    const errorMargin = 0.1;

    const typedCalories = +state.calories;
    const computedCalories = getCaloriesSumByWeight();

    const typedVsComputedRatio = Math.abs(
      parseFloat(Math.abs(typedCalories / computedCalories || 0).toFixed(2)) - 1
    );

    const error = parseFloat(typedVsComputedRatio.toFixed(2)) > errorMargin;

    if (typedCalories && error) {
      return (
        <Danger>
          <Trans
            i18nKey="ingredients.calorificAlert"
            typedCalories={typedCalories}
            computedCalories={computedCalories}
          >
            Wpisana kaloryczność to <b>{{ typedCalories }} kcal</b>. Według
            wyliczeń na makroskładnikach, kaloryczność powinna wynosić{' '}
            <b>{{ computedCalories }} kcal</b>. Upewnij się, że w konfiguracji
            makroskładników i kaloryczności, nie popełniono błędu.
          </Trans>
        </Danger>
      );
    }
  };

  const setDefaultLanguage = language => {
    setState(prev => ({ ...prev, defaultLanguage: language }));
  };

  const loadIngredient = selectedIngredient => {
    setState(prev => ({
      ...prev,
      __originalRecord: mapApiResponseToStateObject(selectedIngredient),
      ...mapApiResponseToStateObject(selectedIngredient),
    }));
  };

  const validateObligatoryFields = () =>
    state.name &&
    state.categories.length > 0 &&
    state.weight !== '' &&
    state.calories !== '' &&
    state.unit &&
    state.wom &&
    state.wot;

  const submitForm = event => {
    event.preventDefault();

    if (state.edit && state.wom !== state.__originalRecord.wom) {
      openConfirmNestedUpdatesModal();
    } else {
      submitData();
    }
  };

  const submitData = (nestedUpdates = {}) => {
    if (!validateObligatoryFields()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }
    const toNull = state => (state === '' ? null : state);

    const data = {
      name: state.name ?? '',
      workName: state.workName
        ? state.workName.toString()
        : state.name['@ContentTranslation'][`${state.defaultLanguage}`],
      shoppingName: state.shoppingName ? state.shoppingName.toString() : '',
      unit: state.unit,
      categories: state.categories.map(category => category['@id']),
      allergens: state.allergens.map(allergen => allergen['@id']),
      weight: state.weight,
      calories: state.calories,
      protein: toNull(state.proteins),
      carbohydrates: toNull(state.carbohydrates),
      fat: toNull(state.fat),
      thermalProcessing: toNull(state.wot),
      glycemicIndex: toNull(state.glycemicIndex),
      roughage: toNull(state.dietaryFiber),
      saturatedFattyAcids: toNull(state.nkt),
      salt: toNull(state.salt),
      sugar: toNull(state.monosaccharide),
      price: toNull(parseFloat((state.pricePerKg / 10).toFixed(4))),
      workingOnMachining: toNull(state.wom),
      wholesaler: state.wholesaler,
      hermesCardIndex: `${state.hermesCardIndex}`,
      inventoryAmount: toNull(state.inventoryAmount),
      contractor: toNull(state.contractor),
      note: state.note,
      homeIngredient: state.homeIngredient,
      allert: state.allert,
      nestedUpdates: nestedUpdates,
      minerals: state.minerals,
      vitamins: state.vitamins,
      fattyAcids: state.fattyAcids,
    };

    const action = state.edit
      ? put(`/ingredients/${match.params.id}`, data)
      : post('/ingredients', data);

    action.then(
      () => history.push('/admin/ingredients'),
      error => {
        toast.error(
          error.response.data['hydra:description'] || t('form.smthWentWrong')
        );
      }
    );
  };

  const handleChange = event => {
    const isNumber = !_.isNaN(parseInt(event.target.value, 10));

    setState(prev => ({
      ...prev,
      [event.target.name]: isNumber
        ? _.toNumber(event.target.value)
        : event.target.value === ''
        ? null
        : event.target.value,
    }));
  };

  const handleNutrients = (event, sectionName) => {
    const isNumber = !_.isNaN(parseInt(event.target.value, 10));
    setState(prevState => ({
      ...prevState,
      [sectionName]: {
        ...prevState[sectionName],
        // [event.target.name]: parseFloat(event.target.value),
        [event.target.name]: isNumber
          ? _.toNumber(event.target.value)
          : event.target.value === ''
          ? null
          : event.target.value,
      },
    }));
  };

  const recalculatePricePerKg = event => {
    const parsedInt = parseFloat(event.target.value);
    const pricePerKg = (state.pricePerUnit * (1000 / parsedInt)).toFixed(2);

    setState(prev => ({ ...prev, pricePerKg }));
  };

  const handleWeightBeforeProcessingChange = event => {
    if (state.pricePerUnit > 0) {
      recalculatePricePerKg(event);
    }
    handleChange(event);
  };

  const handleWeightChange = ({ target: { name, value } }) => {
    const inputName = name;

    if (countBeforeDecimal(value) > 13) return;

    switch (inputName) {
      case 'pricePerKg': {
        const pricePerKg =
          countDecimal(value) <= 2 ? value : toDecimalPlaces(value, 2);
        setState(prev => ({ ...prev, pricePerKg }));

        if (state.weight) {
          const pricePerUnit = (pricePerKg * (state.weight / 1000)).toFixed(4);

          setState(prev => ({ ...prev, pricePerUnit }));
        }

        break;
      }

      case 'pricePerUnit': {
        const pricePerUnit =
          countDecimal(value) <= 4 ? value : toDecimalPlaces(value, 4);
        setState(prev => ({ ...prev, pricePerUnit }));

        if (state.weight) {
          const pricePerKg = pricePerUnit / (state.weight / 1000);
          const cutDecimalPricePerKg =
            countDecimal(pricePerKg) <= 4
              ? pricePerKg
              : toDecimalPlaces(pricePerKg, 4);

          setState(prev => ({
            ...prev,
            pricePerKg: parseFloat(cutDecimalPricePerKg).toFixed(2),
          }));
        }
        break;
      }

      default:
    }
  };

  const basicOptions = [
    {
      label: (
        <>
          <span>{t('form.field.nameForClient') + '*'}</span>{' '}
          <Tooltip
            title={
              <div>
                <h4>{t('form.field.nameTooltip1')}</h4>
              </div>
            }
            placement="right"
          >
            <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
          </Tooltip>
        </>
      ),
      id: 'nameInput',
      type: 'text',
      name: 'name',
      value: state.name,
    },
    {
      label: t('form.field.weightBeforeProcessing') + '*',
      id: 'weightInput',
      type: 'number',
      name: 'weight',
      value: state.weight,
    },
    {
      label: (
        <>
          <span>{t('form.field.workingName')}</span>{' '}
          <Tooltip
            title={
              <div>
                <h4>{t('form.field.workingNameTooltip1')}</h4>
                <h4>{t('form.field.workingNameTooltip2')}</h4>
              </div>
            }
            placement="right"
          >
            <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
          </Tooltip>
        </>
      ),
      id: 'workNameInput',
      type: 'text',
      name: 'workName',
      value: state.workName,
    },
    {
      label: (
        <>
          <span>{t('form.field.shoppingName')}</span>{' '}
          <Tooltip
            title={
              <div>
                <h4>{t('form.field.shoppingNameTooltip1')}</h4>
                <h4>{t('form.field.shoppingNameTooltip2')}</h4>
              </div>
            }
            placement="right"
          >
            <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
          </Tooltip>
        </>
      ),
      id: 'shoppingNameInput',
      type: 'text',
      name: 'shoppingName',
      value: state.shoppingName,
    },
  ];

  const options = [
    {
      label: t('form.field.fat'),
      id: 'fatInput',
      type: 'number',
      name: 'fat',
      value: state.fat,
    },
    {
      label: t('form.field.absorbableCarbohydrates'),
      id: 'carbohydratesInput',
      type: 'number',
      name: 'carbohydrates',
      value: state.carbohydrates,
    },
    {
      label: t('form.field.simpleSugars'),
      id: 'monosaccharideInput',
      name: 'monosaccharide',
      type: 'number',
      value: state.monosaccharide,
    },
    {
      label: t('form.field.protein'),
      id: 'proteinsInput',
      type: 'number',
      name: 'proteins',
      value: state.proteins,
    },
    {
      label: t('form.field.fiber'),
      id: 'dietaryFiberInput',
      type: 'number',
      name: 'dietaryFiber',
      value: state.dietaryFiber,
    },
    {
      label: t('form.field.salt'),
      id: 'saltInput',
      type: 'number',
      name: 'salt',
      value: state.salt,
    },
    {
      label: t('form.field.nkt'),
      id: 'nktInput',
      type: 'number',
      name: 'nkt',
      value: state.nkt,
    },
    {
      label: t('form.field.glycemicIndex.label'),
      id: 'glycemicIndexInput',
      type: 'number',
      name: 'glycemicIndex',
      value: state.glycemicIndex,
    },
    {
      label: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {t('form.field.caloriesPer100g')} *
          <Tooltip
            title={
              <div>
                <h4>{t('form.caloriesInfo1')}</h4>
                <h4>{t('form.caloriesInfo2')}</h4>
                <br />
                <h4>{t('form.caloriesInfo3')}</h4>
                <h4>{t('form.caloriesInfo4')}</h4>
                <h4>{t('form.caloriesInfo5')}</h4>
              </div>
            }
            placement="right"
          >
            <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
          </Tooltip>
        </div>
      ),
      id: 'caloriesInput',
      type: 'number',
      name: 'calories',
      value: state.calories,
    },
    {
      label: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {t('common.carbohydratesNet')} *
          <Tooltip
            title={
              <div>
                <h4>{t('form.carbohydratesNet.tooltip1')}</h4>
                <h4>{t('form.carbohydratesNet.tooltip2')}</h4>
              </div>
            }
            placement="right"
          >
            <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
          </Tooltip>
        </div>
      ),
      id: 'carbohydratesNetInput',
      type: 'number',
      name: 'carbohydratesNet',
      value: Math.max(0, state.carbohydrates - state.dietaryFiber).toFixed(2),
      inputProps: {
        disabled: true,
        onChange: () => false,
      },
    },
  ];

  const processingOptions = [
    {
      label: t('form.field.wot') + '*',
      id: 'wotInput',
      type: 'number',
      name: 'wot',
      value: state.wot,
    },
    {
      label: t('form.field.wom') + '*',
      id: 'womInput',
      type: 'number',
      name: 'wom',
      value: state.wom,
    },
  ];

  const salesOptions = currency => [
    {
      label: t('form.field.pricePerUnitCurr', {
        replace: { currency },
      }),
      id: 'pricePerUnitInput',
      type: 'number',
      name: 'pricePerUnit',
      value: state.pricePerUnit || state.price * (state.weight / 1000),
    },
    {
      label: t('form.field.pricePerKgCurr', {
        replace: { currency },
      }),
      id: 'pricePerKgInput',
      type: 'number',
      name: 'pricePerKg',
      value: state.pricePerKg,
    },
  ];

  const additionalSalesOptions = [
    {
      label: t('form.field.availability'),
      id: 'inventoryAmount',
      type: 'number',
      name: 'inventoryAmount',
      value: state.inventoryAmount,
      disabled: state?.products?.length > 0,
    },
  ];

  const salesSelectsOptions = [
    {
      Component: FormSelectSingle,
      label: t('form.field.wholesalers'),
      options: wholesalers,
      value: state.wholesaler,
      name: 'wholesaler',
      id: 'wholesaler',
      mapBy: 'value',
      trackBy: '@id',
    },
  ];

  const selectsOptions = [
    {
      Component: FormSelectMultiple,
      label: t('form.field.categories') + '*',
      options: ingredientCategories,
      value: ingredientCategories?.filter(el =>
        state?.categories?.some(filter => el['@id'] === filter['@id'])
      ),
      name: 'categories',
      id: 'categories',
      mapBy: 'value',
    },
    {
      Component: FormSelectSingle,
      label: t('form.field.unit') + '*',
      options: units,
      value: state.unit,
      name: 'unit',
      id: 'unit',
      mapBy: 'value',
      trackBy: '@id',
    },
    {
      Component: FormSelectMultiple,
      name: 'allergens',
      id: 'allergens',
      options: allergens,
      value: allergens?.filter(el =>
        state.allergens?.some(filter => el['@id'] === filter['@id'])
      ),
      label: t('form.field.allergens'),
      mapBy: 'value',
    },
  ];

  const props = {
    match,
    t,
    currency,
    classes,
    state,
    isLoading,
    handleToggle,
    closeConfirmNestedUpdatesModal,
    caloriesErrorDetector,
    setDefaultLanguage,
    submitForm,
    submitData,
    handleChange,
    handleNutrients,
    handleWeightBeforeProcessingChange,
    handleWeightChange,
    basicOptions,
    options,
    processingOptions,
    salesOptions,
    additionalSalesOptions,
    salesSelectsOptions,
    selectsOptions,
  };
  return <FormIngredientsView props={props} />;
};

const mapStateToProps = state => ({
  currency: state.Brands.brand.multinational.currency,
  allergens: state.Allergens.allergens,
  units: state.Units.units,
  ingredient: state.Ingredients.ingredient,
  ingredientCategories: state.Ingredients.ingredientCategories,
  wholesalers: state.Wholesalers.wholesalers,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAllergens,
    fetchUnits,
    fetchIngredientCategories,
    fetchIngredient,
    fetchWholesalers,
  })(withStyles(extendedFormsStyle)(FormIngredientsContainer))
);
