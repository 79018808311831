import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';

import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';

const PayNowModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const paynow = useCheckIsPaymentDisabled('PAYNOW');

  useEffect(() => {
    if (paynow.isSelected) {
      handleModuleUpdate({
        ...module,
        enabled: true,
      });
    }
  }, [paynow.isSelected]);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayNowModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <h3 style={{ display: 'flex', gap: '4px', marginLeft: '15px' }}>
            {t('paymentType.title.PayNow', 'Ustawienia PayNow')}
            <InfoWithTooltip
              link={EXTERNAL_URLS.configurationPayNow}
              linkText={t('paymentType.configurationPayNow.tooltip')}
            />
          </h3>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <FormTextInput
          label="Api Key"
          classes={classes}
          customInput={FormTextInput}
          value={module.apiKey}
          onChange={e =>
            handleModuleUpdate({ ...module, apiKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Signature Key"
          classes={classes}
          customInput={FormTextInput}
          value={module.signatureKey}
          onChange={e =>
            handleModuleUpdate({ ...module, signatureKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayNowModule;
