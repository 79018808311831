import {
  Router,
  Route,
  Switch,
  withRouter,
  useRouteMatch,
  useHistory,
} from 'react-router';

import List from 'views/MenuPlanner/MenuMessages/List';
import Form from 'views/MenuPlanner/MenuMessages/Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_NOTIFICATION,
  ROLE_EDIT_NOTIFICATION,
  ROLE_SHOW_NOTIFICATION,
} from 'helpers/roles';

const MenuMessages = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const ListSecured = withRole(ROLE_SHOW_NOTIFICATION, List);
  const CreateForm = withRole(ROLE_CREATE_NOTIFICATION, Form);
  const EditForm = withRole(ROLE_EDIT_NOTIFICATION, Form);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={`${match.url}/`} component={ListSecured} />
        <Route path={`${match.url}/add`} component={CreateForm} />
        <Route path={`${match.url}/edit/:id`} component={EditForm} />
      </Switch>
    </Router>
  );
};

export default withRouter(MenuMessages);
