import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { orderStyles, singleDayStyles } from '../../styles';
import SelectInput from 'components/FormSelect/SelectInput';
import { openSweetToast } from 'actions/App';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { get, put } from 'helpers/apiHelpers';
import Button from 'components/CustomButtons/Button';
import Accordion from 'components/Accordion/Accordion';
import Datetime from 'react-datetime';
import FormLabel from '@material-ui/core/FormLabel';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import BagChangeModal from './bagChangeModal';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Add, DeleteForever, Info, Remove } from '@material-ui/icons';
import DeleteBagSection from './SingleDayDetailsComponents/DeleteBagSection';
import CopyPaymentLinkDialog from 'views/Orders/Orders/NewOrder/components/CopyPaymentLinkDialog/CopyPaymentLinkDialog';
import ModalReturnPoints from 'components/ModalReturnPoints/ModalReturnPoints';
import makeStyles from '@material-ui/styles/makeStyles';
import ModalRemoveAdditionalDishes from 'components/ModalRemoveAdditionalDishes/ModalRemoveAdditionalDishes';
import Select from 'components/CustomSelect/Select';
import { sortByKey } from 'helpers/helpers';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  countButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid RGB(196,196,196)',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  countPresentation: {
    fontSize: '1.1rem',
    marginLeft: '10px',
    marginRight: '10px',
  },
  binIcon: {
    cursor: 'pointer',
    marginLeft: '30px',
    color: 'RGB(244,67,54)',
  },
});

const renderAddonElement =
  onClick =>
  ({ addon, quantity }, index) => {
    const quantityPrefix = quantity > 1 ? `${quantity}x ` : '';
    return (
      <li key={index} style={singleDayStyles.addonElement}>
        <Button
          justIcon
          round
          simple
          onClick={() => onClick(addon?.['@id'])}
          color="danger"
          size="lg"
          className="remove"
        >
          <DeleteForeverIcon />
        </Button>
        <span style={{ wordBreak: 'break-word' }}>
          {quantityPrefix}
          {addon.name}
        </span>
      </li>
    );
  };

const renderSuspendButton = ({
  toggleSuspensionOfSelectedDay,
  holdOnButtonCopy,
  isSelectedDaySubscriptionIntent,
  isSuspendLoading,
}) => {
  if (!isSelectedDaySubscriptionIntent) return null;
  if (isSuspendLoading)
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress size={20} />
      </div>
    );
  return (
    <Button color="info" onClick={toggleSuspensionOfSelectedDay}>
      <b>{holdOnButtonCopy}</b>
    </Button>
  );
};

const renderNotAvailableOptionInSubscriptionTooltip = t => {
  return (
    <Tooltip
      title={
        <h4>
          {t(
            'tooltips.subscription.disabledOption',
            'Tej opcji nie można zmienić w dniu subskrypcyjnym.'
          )}
        </h4>
      }
      placement="right"
    >
      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
    </Tooltip>
  );
};

const SingleDayDetails = ({
  bagInfo,
  match,
  classes,
  variants,
  diets,
  setBagInfo,
  setAddressType,
  addressType,
  pickupPoints,
  clientAddresses,
  fetchingDay,
  selectedDiet,
  selectDiet,
  calendarNeedsUpdate,
  isSelectedDaySubscriptionIntent,
  dietInfo,
  setCalendarNeedsUpdate,
  brandInfo,
  orderInfo,
  removeAddons,
  mealTypes,
}) => {
  const { t } = useTranslation();
  const style = useStyles();

  const WEEKDAY_NAMES_LONG = moment.localeData().weekdays();
  const MONTH_NAMES = moment.localeData().months();

  const applyForRestBags = false;
  const [multipleChange, setMultipleChange] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [createOrder, setCreateOrder] = useState(
    orderInfo.status.systemValue !== 'WAITING_FOR_PAYMENT'
  );
  const [bagChangeModal, setBagChangeModal] = useState(false);
  const [bagChanges, setBagChanges] = useState(null);
  const [pickerDate, setPickerDate] = useState(null);
  const [dietDays, setDietDays] = useState([]);
  const [changedAttributes, setChangedAttributes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuspendLoading, setIsSuspendLoading] = useState(false);
  const [bagSnap, setBagSnap] = useState(null);
  const [addonIdToRemove, setAddonIdToRemove] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState(false);
  const [paymentToPay, setPaymentToPay] = useState(null);
  const [isOpenReturnCost, setIsOpenReturnCost] = useState(false);
  const [
    isOpenRemoveAdditionalDishes,
    setIsOpenReturnCostRemoveAdditionalDishes,
  ] = useState(false);
  const [returnCost, setReturnCost] = useState(null);
  const [selectedMealTypes, setSelectedMealTypes] = useState([]);

  const [mealTypesAdditionalOrder, setMealTypesAdditionalOrder] = useState([]);
  const [listAdditionalOrder, setListAdditionalOrder] = useState({});
  const [resignDishItem, setResignDishItem] = useState([]);

  const handleCountClick = (selected, type) => {
    setListAdditionalOrder(prev => {
      const updatedOrder = { ...prev };
      if (type === 'addition') {
        updatedOrder[selected] = (updatedOrder[selected] || 0) + 1;
      } else if (type === 'subtract') {
        updatedOrder[selected] = (updatedOrder[selected] || 0) - 1;
      }

      return updatedOrder;
    });
  };

  const bagDate = bagInfo ? bagInfo.date : null;

  const isSelectedDaySuspended = bagInfo?.newState === 'SUBSCRIPTION_SUSPENDED';

  const getMealTypesLabels = mealTypesInput =>
    mealTypesInput?.map(mealType => ({
      label: mealTypes.find(meal => meal['@id'] === mealType)?.name,
      value: mealType,
    }));

  useEffect(() => {
    if (
      bagInfo?.variant?.superVariantBeta &&
      !changedAttributes.includes('mealTypes')
    ) {
      const newMealTypes = bagInfo?.variantMealTypeCombination?.mealTypes
        ? bagInfo?.variantMealTypeCombination?.mealTypes?.map(el => ({
            label: el.name,
            value: el['@id'],
          }))
        : getMealTypesLabels(bagInfo?.variant?.mealTypes);
      setSelectedMealTypes(newMealTypes);
    }

    if (
      bagInfo?.variant?.superVariantBeta &&
      changedAttributes.includes('mealTypes')
    ) {
      const newMealTypes = getMealTypesLabels(bagInfo?.variant?.mealTypes);
      setSelectedMealTypes(newMealTypes);
    }
  }, [bagInfo?.variant]);

  useEffect(() => {
    bagDate && setPickerDate(new moment(bagDate));
    bagInfo && setBagSnap(bagInfo);
    setMultipleChange(false);
    setChangeDate(false);
    setChangedAttributes([]);
    setDietDays([]);
    setMealTypesAdditionalOrder([]);
    setListAdditionalOrder([]);

    checkIfBagIsComplete(bagInfo);
  }, [bagDate]);

  useEffect(() => {
    if (pickerDate) {
      fetchOnDateChange();
    }
  }, [pickerDate]);

  let datesLength = bagInfo && bagInfo.dates ? bagInfo.dates.length : 0;
  let applyForDatesLength =
    bagInfo && bagInfo.applyForDates ? bagInfo.applyForDates.length : 0;

  const buildDataForSubscriptionChange = () => {
    if (bagInfo === null) return {};
    return changedAttributes.reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: (function (objField) {
          if (objField === null) return null;
          if (objField?.['@id'] === undefined) return bagInfo[curr];
          return bagInfo[curr]?.['@id'];
        })(bagInfo[curr]),
      };
    }, {});
  };

  const checkIfBagIsComplete = bag => {
    const message = {
      show: false,
      mainMessage: t('errors.selectedConfigurationNotSupportedAnymore'),
      dietMessage: '',
      variantMessage: '',
      calorificMessage: '',
      footerMessage: t('errors.chooseSupportedConfigurationAppointment'),
    };

    if (bag) {
      const currentlySelectedDiet = diets.find(
        diet => bag.diet?.['@id'] === diet?.['@id']
      );

      if (!bag.diet && (bag.addons || bag.dishSizeItems)) {
      } else {
        if (
          (currentlySelectedDiet && !currentlySelectedDiet.active) ||
          !bag.diet
        ) {
          message.show = true;
          message.dietMessage = t('errors.dietNotSupported');
        }

        if (!bag.variant?.active || !bag.variant) {
          message.show = true;
          message.variantMessage = t('errors.variantNotSupported');
        }

        if (!bag.calorific) {
          message.show = true;
          message.calorificMessage = t('errors.calorificNotSupported');
        }
      }
    }

    if (message.show) {
      const {
        mainMessage,
        dietMessage,
        variantMessage,
        calorificMessage,
        footerMessage,
      } = message;

      toast.error(
        [
          mainMessage,
          dietMessage,
          variantMessage,
          calorificMessage,
          footerMessage,
        ]
          .filter(Boolean)
          .join(' ')
      );
    }
  };

  const toggleSuspensionOfSelectedDay = () => {
    const selectedDate = bagInfo.date;
    const subscriptionId = dietInfo.subscription?.id;
    setIsSuspendLoading(true);
    put(`subscription/${subscriptionId}`, {
      [isSelectedDaySuspended ? 'enableDays' : 'disableDays']: [selectedDate],
    })
      .then(() => {
        setCalendarNeedsUpdate(true);
        toast.success(t('success.changesSaved'));
      })
      .catch(error => {
        const message =
          error.response && error.response.data.violations
            ? error.response.data.violations[0]?.message
            : t('notify.cannotSave');
        toast.error(message);
      })
      .finally(() => {
        setIsSuspendLoading(false);
      });
  };

  const fetchOnDateChange = () => {
    const dateFormat = 'YYYY-MM-DD';
    const startOfMonth = pickerDate.startOf('month').format(dateFormat);

    const amountOfDaysToSubtract = new moment(startOfMonth).isoWeekday() - 1;

    const firstVisibleDay = new moment(startOfMonth)
      .subtract(amountOfDaysToSubtract, 'days')
      .format(dateFormat);
    const lastVisibleDay = new moment(startOfMonth)
      .subtract(amountOfDaysToSubtract, 'days')
      .add(41, 'days')
      .format(dateFormat);

    return fetchDietDays(firstVisibleDay, lastVisibleDay);
  };

  const fetchDietDays = (firstVisibleDay, lastVisibleDay) => {
    if (firstVisibleDay && lastVisibleDay) {
      const url = `/ecommerce-diets/${match.params.id}/calendar/${firstVisibleDay}/${lastVisibleDay}`;

      get(url).then(res => {
        const daysObject = res.days;

        let dietDaysArray = [];

        Object.keys(daysObject).forEach(day => {
          const formattedDay = new moment(daysObject[day].date).format(
            'YYYY-MM-DD'
          );

          if (
            !dietDays.includes(formattedDay) &&
            daysObject[day].state !== 'NO_DIET' &&
            daysObject[day].state !== 'ADD_DIET' &&
            daysObject[day].state !== 'NOT_DIET_CAN_PLACE_ORDER'
          ) {
            dietDaysArray.push(formattedDay);
          }
        });

        setDietDays([...dietDays, ...dietDaysArray]);
      });
    }
  };

  const addToChangedAttributes = attributes => {
    let newAttributes = [...changedAttributes];
    attributes.forEach(attribute => {
      changedAttributes.indexOf(attribute) === -1 &&
        newAttributes.push(attribute);
    });
    setChangedAttributes(newAttributes);
  };

  const removeMultipleFromChangedAttributes = attributes => {
    let newAttributes = [...changedAttributes];

    attributes.forEach(attribute => {
      newAttributes = newAttributes.filter(
        attrToFilter => attrToFilter !== attribute
      );
    });

    setChangedAttributes(newAttributes);
  };

  const removeFromChangedAttributes = attribute => {
    const newAttributes = changedAttributes.filter(
      changedAttribute => changedAttribute !== attribute
    );

    setChangedAttributes(newAttributes);
  };

  const getCurrentPickerDate = action => {
    if (pickerDate) {
      const currentMoment = new moment(pickerDate);

      switch (action) {
        case 'add':
          currentMoment.add(1, 'month');
          break;

        case 'subtract':
          currentMoment.subtract(1, 'month');
          break;

        default:
          break;
      }

      setPickerDate(currentMoment);
    }
  };

  const getMealTypesOptions = () => {
    const mainVariant = variants?.filter(
      variant => variant?.['@id'] === bagInfo?.variant?.['@id']
    );
    const variantMealTypes = mainVariant?.[0]?.mealTypes;

    const optionMealTypes = mealTypes
      ?.filter(mealType => {
        if (variantMealTypes?.includes(mealType?.['@id'])) return mealType;
      })
      .map(el => {
        return { label: el?.name, value: el?.['@id'] };
      });
    return optionMealTypes;
  };

  const addresses = clientAddresses.map(address => ({
    ...address,
    label: `${address.street} ${address.buildNumber}, ${address.postCode} ${address.city}`,
  }));

  const validateBag = bagInfo => {
    if (!bagInfo?.address && !bagInfo?.pickUpPoint) {
      toast.error(t('errors.deliveryOtionRequired'));
      return true;
    } else if (
      bagInfo?.variant?.superVariantBeta &&
      isEmpty(selectedMealTypes)
    ) {
      toast.error(t('errors.mealTypesRequired'));
      return true;
    } else if (!bagInfo.calorific) {
      toast.error(t('errors.calorificRequired'));
      return true;
    } else if (multipleChange && !bagInfo.applyForDates) {
      toast.error(t('errors.selectDayToAmend'));
      return true;
    } else if (
      multipleChange &&
      changeDate &&
      bagInfo.dates.length < bagInfo.applyForDates.length
    ) {
      toast.error(t('errors.datesForDietDaysRequired'));
      return true;
    } else if (bagInfo.dateToChange && !isEmpty(bagInfo.dishSizeItems)) {
      toast.error(t('errors.removeAdditionalDishesBeforeDateChange'));
      return true;
    }

    return false;
  };

  const changeDay = (bagInfo, acceptedReturnCost = false) => {
    setIsLoading(true);

    const bagDate = new moment(bagInfo.date).format('YYYY-MM-DD');
    const clientDietId = bagInfo?.clientDiet?.id;

    const isSelectedDaySubscriptionIntent =
      bagInfo['@type'] === 'SubscriptionIntent';

    const requestUrl = isSelectedDaySubscriptionIntent
      ? `/ecommerce-diets/${bagInfo.clientDiet.id}/delivery-days`
      : `/v2/calendar/${clientDietId}/days/${bagDate}`;

    const data = {
      paidMutation: createOrder,
      transformToOrder: {},
      changeDetails: {
        ...(acceptedReturnCost && { acceptedReturnCost: returnCost }),
        applyForDates: multipleChange
          ? bagInfo.applyForDates.map(date =>
              new moment(date).format('YYYY-MM-DD')
            )
          : applyForRestBags
          ? []
          : [new moment(bagInfo.date).format('YYYY-MM-DD')],
      },
    };

    changedAttributes.forEach(key => {
      if (key === 'variant') {
        data.changeDetails[key] = bagInfo?.[key]['@id'];
      }
      if (key === 'calorific') {
        data.changeDetails[key] = bagInfo?.[key]['@id'];
      }
      if (key === 'diet') {
        data.changeDetails[key] = bagInfo?.[key]['@id'];
      }
      if (key === 'mealTypes') {
        data.changeDetails[key] = selectedMealTypes.map(
          mealType => mealType.value
        );
      }
      if (key === 'address' || key === 'pickUpPoint') {
        data.changeDetails.delivery = {
          address: bagInfo.address ? bagInfo.address['@id'] : null,
          pickUpPoint: bagInfo.pickUpPoint ? bagInfo.pickUpPoint['@id'] : null,
        };
      }
      if (key === 'optionChangeMenu') {
        data.changeDetails[key] = bagInfo?.[key];
      }
      if (key === 'dates') {
        const dateFrom = bagInfo?.applyForDates
          ? bagInfo?.applyForDates?.map(date =>
              new moment(date).format('YYYY-MM-DD')
            )
          : [moment(bagInfo.date).format('YYYY-MM-DD')];

        const dateTo = multipleChange
          ? bagInfo.dates
            ? bagInfo.dates.map(date => new moment(date).format('YYYY-MM-DD'))
            : []
          : [
              new moment(
                bagInfo.dateToChange ? bagInfo.dateToChange : bagInfo.date
              ).format('YYYY-MM-DD'),
            ];

        const conc = dateFrom
          .map((el, index) => ({
            [el]: dateTo[index],
          }))
          .reduce((acc, obj) => {
            return { ...acc, ...obj };
          }, {});

        data.changeDetails[key] = conc;
      }
      if (key === 'useEcoContainers') {
        data.changeDetails[key] = bagInfo?.[key];
      }
      if (key === 'applyForRestDays') {
        data.changeDetails[key] = bagInfo?.[key];
      }
      if (key === 'boughtMealTypes') {
        const boughtMealTypes = Object.entries(listAdditionalOrder).map(
          ([mealType, quantity]) => ({
            mealType,
            quantity,
          })
        );
        if (Object.values(listAdditionalOrder).length === 0) return;
        data.changeDetails[key] = boughtMealTypes;
      }
      if (key === 'resignDishItems') {
        data.changeDetails[key] = [resignDishItem];
      }
    });

    const dataForSubscriptionChange = {
      date: moment(bagDate).format('YYYY-MM-DD'),
      ...buildDataForSubscriptionChange(),
      ...(() => (bagInfo.key ? { costAcceptKey: bagInfo.key } : {}))(),
    };

    put(
      requestUrl,
      isSelectedDaySubscriptionIntent ? dataForSubscriptionChange : data
    )
      .then(res => {
        setIsLoading(false);
        setChangedAttributes([]);

        setCalendarNeedsUpdate(!calendarNeedsUpdate);
        setDietDays([]);
        fetchOnDateChange();

        if (res['@type'] === 'Order') {
          setIsOpen(true);
          setPaymentLink(res.paymentLink);
          setPaymentToPay(res.priceLeftToPay);
        }

        if (res['@type'] === 'Bag') {
          toast.success(t('success.changesSaved'));
        }
      })
      .catch(async error => {
        setIsLoading(false);
        if (error.response.status == '409') {
          setReturnCost(error?.response?.data?.returnCost);
          setIsOpenReturnCost(true);
        }
      });
  };

  const confirmDeleteAddons = async () => {
    await removeAddons(addonIdToRemove);
    setAddonIdToRemove(null);
    toast.warning(t('warnings.clientsMoneybox'));
  };

  const isDietDay = currentDay => {
    return dietDays.includes(currentDay);
  };

  const isDisabledDay = (current, enablePastDays) => {
    let key;
    key = new moment(current).locale('en').format('dddd').toLowerCase();

    const isToday = DateUtils.isSameDay(new Date(current), new Date());
    const isPastDay = enablePastDays
      ? false
      : new Date(current) < new Date() && !isToday;
    const isDisabledInConfigDay = brandInfo[key].shouldBeDeliveredInBlock === 0;
    const isCustomDisabledDay = brandInfo.disabledDays.includes(
      moment(current).format('YYYY-MM-DD') + ' 00:00:00'
    );

    return isDisabledInConfigDay || isPastDay || isCustomDisabledDay;
  };

  const bagHasBeenDelivered =
    bagInfo &&
    !DateUtils.isSameDay(new Date(bagInfo.deliveredIn), new Date()) &&
    new Date(bagInfo.deliveredIn) < new Date();
  const isEditDisabled = orderInfo.status.systemValue === 'CANCELED';

  const selectedDate = useMemo(
    () =>
      bagInfo &&
      moment(bagInfo?.dateToChange ? bagInfo.dateToChange : bagInfo.date),
    [bagInfo]
  );

  const isSelectedDaySubscription = bagInfo?.clientDiet?.isSubscription; // counts for Intents and Bags made from intents

  const holdOnButtonCopy = t(
    isSelectedDaySuspended ? 'subscription.resume' : 'subscription.hold'
  );

  const variantsOptions = sortByKey(
    variants.filter(variant =>
      selectedDiet?.variants?.includes(variant?.['@id'])
    ),
    'position'
  )
    .sort((_, b) => (b.active ? 1 : -1))
    .map(variant => ({
      label: `${variant?.name} ${
        variant?.active ? '' : `(${t('$*common.inactive.variant')})`
      }`,
      value: variant?.['@id'],
    }));

  const dietsOptions = sortByKey(diets, 'position')
    .sort((a, b) => (b.active ? 1 : -1))
    .map(diet => ({
      label: `${diet?.name} ${
        diet?.active ? '' : `(${t('$*common.inactive.diet')})`
      }`,
      value: diet?.['@id'],
    }));

  const calories = variants?.find(
    variant => variant?.['@id'] === bagInfo?.variant?.['@id']
  )?.calories ?? [{}];

  const caloriesOptions = calories
    .sort((_, b) => (b.active ? 1 : -1))
    .map(calorie => ({
      label: `${calorie?.name} ${
        calorie?.active ? '' : `(${t('$*common.inactive.diet')})`
      }`,
      value: calorie['@id'],
    }));

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {fetchingDay && <CircularProgress size={100} />}
      </div>
      <div
        style={{
          position: 'relative',
          right: fetchingDay || false ? '2000px' : '0',
          transition: '0.5s all',
        }}
      >
        <div
          style={orderStyles.box}
          onClick={() => {
            if (isEditDisabled)
              return toast.info(
                `${t('newOrder.singleDayDetails.cancelOrder')} ${t(
                  'newOrder.singleDayDetails.notModificationInfo'
                )}`
              );
            else if (bagHasBeenDelivered) {
              return toast.info(
                `${t('newOrder.singleDayDetails.deliveredBag')} ${t(
                  'newOrder.singleDayDetails.notModificationBag'
                )}`
              );
            }
          }}
        >
          {bagInfo ? (
            <h1
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <b>
                {moment(bagInfo.date).format(
                  moment.localeData().longDateFormat('L')
                )}
              </b>
              {renderSuspendButton({
                toggleSuspensionOfSelectedDay,
                holdOnButtonCopy,
                isSelectedDaySubscriptionIntent,
                isSuspendLoading,
              })}
            </h1>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <h3>
                <Trans i18nKey="singleDayDetails.chooseDietDay">
                  Wybierz dzień diety, aby zobaczyć <b>szczegóły</b>
                </Trans>
              </h3>
            </div>
          )}
          {bagInfo && bagInfo.diet && !isSelectedDaySuspended && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none">{t('clients.edit')}</TableCell>
                    <TableCell padding="none">
                      {t('common.attribute')}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {t('form.value')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEditDisabled && !bagHasBeenDelivered && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id={'multipleChangeCheck'}
                          checked={multipleChange}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscription
                          }
                          onChange={() => {
                            setBagInfo({
                              ...bagInfo,
                              applyForDates: [new Date(bagInfo.date)],
                            });
                            setPickerDate(new moment(bagInfo.date));
                            setMultipleChange(!multipleChange);
                            setChangeDate(false);
                            removeFromChangedAttributes('dates');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor={'multipleChangeCheck'}
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t('singleDayDetails.changeMultipleMonths')}
                          {isSelectedDaySubscription &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {!multipleChange && !bagInfo.clientDiet?.isSubscription && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="dateCheck"
                          checked={changedAttributes.includes('dates')}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                          onChange={() => {
                            setBagInfo({ ...bagInfo, hasChanged: true });
                            !changedAttributes.includes('dates')
                              ? addToChangedAttributes(['dates'])
                              : removeFromChangedAttributes('dates');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="dateCheck"
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t('singleDayDetails.bagDate')}
                          {isSelectedDaySubscriptionIntent &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <Datetime
                          className={'ecommerceDietPicker'}
                          timeFormat={false}
                          dateFormat={moment.localeData().longDateFormat('L')}
                          closeOnSelect={false}
                          onNavigateBack={() => {
                            getCurrentPickerDate('subtract');
                          }}
                          onNavigateForward={() => {
                            getCurrentPickerDate('add');
                          }}
                          value={selectedDate}
                          isValidDate={current => !isDisabledDay(current, true)}
                          onChange={ev => {
                            if (
                              !DateUtils.isSameDay(new Date(ev), new Date()) &&
                              isDietDay(ev.format('YYYY-MM-DD'))
                            ) {
                              return toast.warning(
                                t(
                                  'singleDayDetails.thereIsAnotherBagForThisDay'
                                )
                              );
                            }
                            addToChangedAttributes(['dates']);
                            setBagInfo({
                              ...bagInfo,
                              dateToChange: ev.format('YYYY-MM-DD'),
                              hasChanged: true,
                            });
                          }}
                          inputProps={{
                            readOnly: true,
                            id: 'dates',
                            disabled:
                              isEditDisabled ||
                              bagHasBeenDelivered ||
                              isSelectedDaySubscriptionIntent,
                          }}
                          renderDay={(props, currentDate, selected) => {
                            if (isDietDay(currentDate.format('YYYY-MM-DD'))) {
                              props.className += ' isDietDay';
                            }

                            return (
                              <td {...props}>
                                <div
                                // className={customDayStyle}
                                >
                                  {currentDate.date()}
                                </div>
                              </td>
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  {multipleChange && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <div
                          style={{
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <FormLabel className={classes.labelHorizontal}>
                            {t('singleDayDetails.chooseDaysToApplyChanges')}
                          </FormLabel>
                          <DayPicker
                            style={{ margin: 0 }}
                            fromMonth={new Date()}
                            months={MONTH_NAMES}
                            month={new Date(bagInfo.date)}
                            weekdaysLong={WEEKDAY_NAMES_LONG}
                            weekdaysShort={moment.localeData().weekdaysMin()}
                            firstDayOfWeek={1}
                            selectedDays={bagInfo.applyForDates}
                            onMonthChange={monthStart =>
                              setPickerDate(new moment(monthStart))
                            }
                            disabledDays={day =>
                              moment(day).isBefore(moment()) ||
                              !isDietDay(new moment(day).format('YYYY-MM-DD'))
                            }
                            onDayClick={(day, { selected }) => {
                              if (
                                DateUtils.isSameDay(new Date(bagInfo.date), day)
                              ) {
                                return toast.warning(
                                  t('cantDeselectCurrentyEditedDay')
                                );
                              }

                              const dayToApplyToIsDisabled =
                                moment(day).isBefore(moment()) ||
                                !isDietDay(
                                  new moment(day).format('YYYY-MM-DD')
                                );

                              if (dayToApplyToIsDisabled) {
                                return;
                              }

                              const selectedDays = bagInfo.applyForDates
                                ? bagInfo.applyForDates
                                : [];

                              if (selected) {
                                const selectedIndex = selectedDays.findIndex(
                                  selectedDay =>
                                    DateUtils.isSameDay(selectedDay, day)
                                );
                                selectedDays.splice(selectedIndex, 1);
                              } else {
                                selectedDays.push(day);
                              }

                              setBagInfo({
                                ...bagInfo,
                                applyForDates: selectedDays,
                                hasChanged: true,
                              });
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell padding="none"></TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}

                  {multipleChange && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="datesChangeCheck"
                          checked={changeDate}
                          onChange={() => {
                            setBagInfo({
                              ...bagInfo,
                              dates: [],
                              hasChanged: true,
                            });
                            removeFromChangedAttributes('dates');
                            setPickerDate(new moment(new Date()));
                            setChangeDate(!changeDate);
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="datesChangeCheck"
                          className={classes.labelHorizontal}
                        >
                          {t('singleDayDetails.changeBagsDays')}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {changeDate && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <div
                          style={{
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <FormLabel className={classes.labelHorizontal}>
                            {t('singleDayDetails.chooseNewBagDates', {
                              datesLength,
                              applyForDatesLength,
                            })}
                          </FormLabel>
                          <DayPicker
                            // fromMonth={new Date()}
                            months={MONTH_NAMES}
                            weekdaysLong={WEEKDAY_NAMES_LONG}
                            weekdaysShort={moment.localeData().weekdaysMin()}
                            firstDayOfWeek={1}
                            selectedDays={bagInfo.dates}
                            onMonthChange={monthStart =>
                              setPickerDate(new moment(monthStart))
                            }
                            disabledDays={day =>
                              isDisabledDay(day, true) ||
                              isDietDay(new moment(day).format('YYYY-MM-DD'))
                            }
                            onDayClick={(day, { selected }) => {
                              if (
                                !selected &&
                                applyForDatesLength > 0 &&
                                datesLength === applyForDatesLength
                              ) {
                                return toast.info(
                                  `${t(
                                    'singleDayDetails.allDaysHaveBeenUsedUp'
                                  )} ${t(
                                    'singleDayDetails.unselectSomeDaysToChooseAnother'
                                  )}`
                                );
                              }

                              const targetDayIsDisabled =
                                isDisabledDay(day, true) ||
                                isDietDay(new moment(day).format('YYYY-MM-DD'));

                              if (targetDayIsDisabled) {
                                return;
                              }

                              addToChangedAttributes(['dates']);
                              const selectedDays = bagInfo.dates
                                ? bagInfo.dates
                                : [];

                              if (selected) {
                                const selectedIndex = selectedDays.findIndex(
                                  selectedDay =>
                                    DateUtils.isSameDay(selectedDay, day)
                                );
                                selectedDays.splice(selectedIndex, 1);
                              } else {
                                selectedDays.push(day);
                              }

                              setBagInfo({
                                ...bagInfo,
                                dates: selectedDays,
                                hasChanged: true,
                              });
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell padding="none"></TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {brandInfo.premiumType === 'PREMIUM' && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                          checked={bagInfo.optionChangeMenu}
                          onChange={() => {
                            addToChangedAttributes(['optionChangeMenu']);
                            setBagInfo({
                              ...bagInfo,
                              optionChangeMenu: !bagInfo.optionChangeMenu,
                              hasChanged: true,
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none" colspan={2}>
                        <FormLabel
                          htmlFor="optionChangeMenuCheck"
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t('singleDayDetails.menuChangeAvailability')}
                          {isSelectedDaySubscriptionIntent &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  )}
                  {brandInfo.ecoContainer && (
                    <TableRow>
                      <TableCell padding="none" colspan={2}>
                        <Checkbox
                          checked={bagInfo.useEcoContainers}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          onChange={() => {
                            addToChangedAttributes(['useEcoContainers']);
                            setBagInfo({
                              ...bagInfo,
                              useEcoContainers: !bagInfo.useEcoContainers,
                              hasChanged: true,
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="ecoContainersCheck"
                          className={classes.labelHorizontal}
                        >
                          {t('singleDayDetails.useEcoPacks')}
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        id="dietChangeCheck"
                        checked={changedAttributes.includes('diet')}
                        disabled={isEditDisabled || bagHasBeenDelivered}
                        onChange={() => {
                          setBagInfo({ ...bagInfo, hasChanged: true });
                          !changedAttributes.includes('diet')
                            ? addToChangedAttributes([
                                'diet',
                                'variant',
                                'calorific',
                              ])
                            : removeMultipleFromChangedAttributes([
                                'diet',
                                'variant',
                                'calorific',
                              ]);
                        }}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    <TableCell colspan="2" padding="none">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px 0',
                        }}
                      >
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                          style={{ paddingRight: '0.3rem' }}
                        >
                          {t('diets.diet')}
                        </FormLabel>
                        <Select
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          options={dietsOptions}
                          value={{
                            label: selectedDiet?.name,
                            value: selectedDiet?.['@id'],
                          }}
                          handleChange={option => {
                            const selectedDiet = diets.find(
                              diet => diet['@id'] === option.value
                            );
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'mealTypes',
                              'calorific',
                            ]);
                            setBagInfo(prev => ({
                              ...prev,
                              diet: selectedDiet,
                              variant: null,
                              calorific: null,
                              hasChanged: true,
                            }));
                            selectDiet(selectedDiet);
                          }}
                          customStyle={{ width: '100%' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px 0',
                        }}
                      >
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                          style={{ paddingRight: '0.3rem' }}
                        >
                          {t('diets.variant')}
                        </FormLabel>
                        <Select
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            !selectedDiet
                          }
                          options={variantsOptions}
                          value={{
                            label: bagInfo.variant?.name,
                            value: bagInfo.variant?.['@id'],
                          }}
                          handleChange={option => {
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'mealTypes',
                              'calorific',
                            ]);
                            setBagInfo(prev => ({
                              ...prev,
                              variant: variants.find(
                                variant => variant['@id'] === option.value
                              ),
                              mealTypes: null,
                              calorific: null,
                              hasChanged: true,
                            }));
                          }}
                          customStyle={{ width: '100%' }}
                        />
                      </div>
                      {bagInfo?.variant?.superVariantBeta && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px 0',
                          }}
                        >
                          <FormLabel
                            htmlFor="dietChangeCheck"
                            className={classes.labelHorizontal}
                            style={{ paddingRight: '0.3rem' }}
                          >
                            {t('diets.mealTypes')}
                          </FormLabel>

                          <Select
                            disabled={isEditDisabled || bagHasBeenDelivered}
                            label="Typy posiłków"
                            options={getMealTypesOptions()}
                            value={selectedMealTypes}
                            multiple={true}
                            isClearable={false}
                            handleChange={option => {
                              addToChangedAttributes([
                                'diet',
                                'variant',
                                'mealTypes',
                                'calorific',
                              ]);
                              if (
                                bagInfo?.variant?.minMealTypes &&
                                bagInfo?.variant?.minMealTypes >=
                                  option.length + 1
                              ) {
                                toast.warning(
                                  t(
                                    'orders.selectedMinMealTypes.supervariantBeta.warnings'
                                  )
                                );
                                return;
                              }

                              setSelectedMealTypes(option);
                              setBagInfo({
                                ...bagInfo,
                                calorific: null,
                                hasChanged: true,
                              });
                            }}
                            customStyle={{ width: '100%' }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px 0',
                        }}
                      >
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                          style={{ paddingRight: '0.3rem' }}
                        >
                          {t('diets.calories')}
                        </FormLabel>
                        <Select
                          options={caloriesOptions}
                          disabled={
                            !(bagInfo.variant && bagInfo.variant.active) ||
                            isEditDisabled ||
                            bagHasBeenDelivered
                          }
                          value={{
                            label: bagInfo.calorific?.name,
                            value: bagInfo.calorific?.['@id'],
                          }}
                          handleChange={option => {
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'mealTypes',
                              'calorific',
                            ]);
                            setBagInfo(prev => ({
                              ...prev,
                              calorific: calories.find(
                                calorific => calorific['@id'] === option.value
                              ),
                              hasChanged: true,
                            }));
                          }}
                          customStyle={{ width: '100%' }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  {bagInfo.addons?.length > 0 && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <p style={singleDayStyles.addonsTitle}>Dodatki</p>
                        <ul style={{ margin: 0, padding: 0 }}>
                          {bagInfo.addons.map(
                            renderAddonElement(setAddonIdToRemove)
                          )}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell colSpan={2} padding="none">
                      {t('orders.additionalMealTypes.orderSummary')}
                    </TableCell>
                    <TableCell padding="none">
                      <SelectInput
                        noGrid
                        classes={classes}
                        disabled={isEditDisabled || bagHasBeenDelivered}
                        mapBy="label"
                        trackBy="value"
                        multiple={true}
                        options={getMealTypesOptions()}
                        value={mealTypesAdditionalOrder}
                        handleChange={(ev, obj) => {
                          addToChangedAttributes(['boughtMealTypes']);
                          setMealTypesAdditionalOrder(ev.target.value);
                          setBagInfo({
                            ...bagInfo,
                            hasChanged: true,
                          });
                        }}
                        size={12}
                      />
                    </TableCell>
                  </TableRow>
                  {mealTypesAdditionalOrder.map(selected => {
                    const selectedMealType = mealTypes.filter(
                      el => el['@id'] === selected
                    );

                    return (
                      <TableRow>
                        <TableCell colSpan={2}>
                          {selectedMealType[0].name}
                        </TableCell>
                        <TableCell>
                          <div className={style.flex}>
                            <div
                              onClick={() => {
                                if (
                                  listAdditionalOrder[selected] === undefined ||
                                  listAdditionalOrder[selected] === 0
                                )
                                  return;

                                handleCountClick(selected, 'subtract');
                              }}
                              className={style.countButton}
                            >
                              <Remove />
                            </div>
                            <div className={style.countPresentation}>
                              {listAdditionalOrder[selected] ?? 0}
                            </div>
                            <div
                              onClick={() => {
                                if (listAdditionalOrder[selected] === 10)
                                  return;
                                handleCountClick(selected, 'addition');
                              }}
                              className={style.countButton}
                            >
                              <Add />
                            </div>

                            <div>
                              <DeleteForever
                                onClick={() => {
                                  const newMealTypesAdditionalOrder =
                                    mealTypesAdditionalOrder.filter(
                                      el => el !== selected
                                    );
                                  setMealTypesAdditionalOrder(
                                    newMealTypesAdditionalOrder
                                  );

                                  const newListAdditionalOrder = Object.keys(
                                    listAdditionalOrder
                                  ).reduce((acc, key) => {
                                    if (key !== selected) {
                                      acc[key] = listAdditionalOrder[key];
                                    }
                                    return acc;
                                  }, {});

                                  setListAdditionalOrder(
                                    newListAdditionalOrder
                                  );
                                }}
                                className={style.binIcon}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        checked={
                          changedAttributes.includes('address') ||
                          changedAttributes.includes('pickUpPoint')
                        }
                        disabled={isEditDisabled || bagHasBeenDelivered}
                        onChange={() => {
                          setBagInfo({ ...bagInfo, hasChanged: true });
                          !changedAttributes.includes('address') ||
                          !changedAttributes.includes('pickUpPoint')
                            ? addToChangedAttributes(['address', 'pickUpPoint'])
                            : removeMultipleFromChangedAttributes([
                                'address',
                                'pickUpPoint',
                              ]);
                        }}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    <TableCell padding="none" colSpan={2}>
                      <FormControlLabel
                        control={
                          <Radio
                            disabled={isEditDisabled || bagHasBeenDelivered}
                            checked={addressType === 'pickup'}
                            onChange={() => {
                              addToChangedAttributes([
                                'address',
                                'pickUpPoint',
                              ]);
                              setAddressType('pickup');
                              setBagInfo({
                                ...bagInfo,
                                pickUpPoint: null,
                                address: null,
                                hasChanged: true,
                              });
                            }}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t('orders.pickupPoint')}
                      />
                      {clientAddresses.length > 0 ? (
                        <FormControlLabel
                          control={
                            <Radio
                              disabled={isEditDisabled || bagHasBeenDelivered}
                              checked={addressType === 'delivery'}
                              onChange={() => {
                                addToChangedAttributes([
                                  'address',
                                  'pickUpPoint',
                                ]);
                                setAddressType('delivery');
                                setBagInfo({
                                  ...bagInfo,
                                  address: null,
                                  pickUpPoint: null,
                                  hasChanged: true,
                                });
                              }}
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={t('diets.addresses')}
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            <Radio
                              disabled={isEditDisabled || bagHasBeenDelivered}
                              onChange={() =>
                                toast.warning(
                                  `${t('errors.userHasNoAnyAddress')} ${t(
                                    'errors.addAddresToUnblockDeliveryOptions'
                                  )}`
                                )
                              }
                              checked={false}
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            <div style={{ textAlign: 'center' }}>
                              <div>{t('orders.delivery')}</div>
                              <div>({t('common.itUnavailable')})</div>
                            </div>
                          }
                        />
                      )}
                      {addressType === 'delivery' ? (
                        <SelectInput
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="label"
                          trackBy="@id"
                          options={addresses}
                          value={bagInfo.address}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes(['address', 'pickUpPoint']);
                            setBagInfo({
                              ...bagInfo,
                              address: obj,
                              pickUpPoint: null,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                      ) : (
                        <SelectInput
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="value"
                          trackBy="@id"
                          options={pickupPoints}
                          value={bagInfo.pickUpPoint}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes(['address', 'pickUpPoint']);
                            setBagInfo({
                              ...bagInfo,
                              pickUpPoint: obj,
                              address: null,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right" padding="none"></TableCell>
                  </TableRow>

                  {!isEditDisabled &&
                    !bagHasBeenDelivered &&
                    !isSelectedDaySubscriptionIntent && (
                      <TableRow>
                        <TableCell padding="none">
                          <Checkbox
                            id="createOrderCheck"
                            checked={
                              orderInfo.status.systemValue ===
                              'WAITING_FOR_PAYMENT'
                                ? false
                                : createOrder
                            }
                            onChange={() => {
                              if (
                                orderInfo.status.systemValue ===
                                'WAITING_FOR_PAYMENT'
                              ) {
                                return toast.info(
                                  `${t('info.theErrorsHasNotBeenPaid')} ${t(
                                    'info.orderWillBUpdated'
                                  )}`
                                );
                              }
                              setCreateOrder(!createOrder);
                            }}
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        </TableCell>
                        <TableCell padding="none" colSpan={2}>
                          <FormLabel
                            htmlFor="createOrderCheck"
                            className={classes.labelHorizontal}
                          >
                            {t('singleDayDetails.includeTheCostOfChanges')}
                          </FormLabel>
                        </TableCell>
                        <TableCell align="right" padding="none"></TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
              {orderInfo.status.systemValue !== 'CANCELED' &&
                !bagHasBeenDelivered && (
                  <div style={orderStyles.centered}>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Button
                          onClick={() => {
                            setChangedAttributes([]);
                            setChangeDate(false);
                            setMultipleChange(false);
                            !bagSnap.address
                              ? setAddressType('pickup')
                              : setAddressType('delivery');
                            return setBagInfo(bagSnap);
                          }}
                          color={'secondary'}
                        >
                          {t('common.cancelChanges')}
                        </Button>
                        <Button
                          disabled={!bagInfo.hasChanged || isLoading}
                          onClick={() => {
                            if (validateBag(bagInfo)) {
                              return;
                            }
                            changeDay(bagInfo);
                          }}
                          color={'primary'}
                        >
                          {bagInfo.hasChanged
                            ? t('common.saveChanges')
                            : t('cmmon.noChangesApplied')}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
        {!isEmpty(bagInfo) && (
          <div style={orderStyles.box}>
            <h1>
              <b>{t('dietDetails.items')}</b>
            </h1>
            {!bagInfo.diet && (
              <div>
                <div style={{ marginTop: '20px' }}>
                  <b>{t('common.mainMenu.addons')}</b>
                  <Divider />
                  <div style={{ marginTop: '10px' }}>
                    {bagInfo.addons && bagInfo.addons.length > 0 ? (
                      bagInfo.addons.map(({ addon, quantity }) => {
                        return (
                          <div
                            style={{
                              marginTop: '5px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>{addon?.name ?? ''}</span>
                            <span>{`x${quantity}`}</span>
                          </div>
                        );
                      })
                    ) : (
                      <span>{t('common.lack')}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>
              <div style={{ marginTop: '20px' }}>
                <b>{t('bagInfo.dishes')}</b>
                <Divider />
                <div style={{ marginTop: '10px' }}>
                  {!isEmpty(bagInfo.dishSizeItems) ? (
                    bagInfo.dishSizeItems.map(({ dishSize }) => {
                      return (
                        <div
                          style={{
                            marginTop: '5px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{dishSize?.name ?? ''}</span>
                        </div>
                      );
                    })
                  ) : (
                    <span>{t('common.lack')}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {bagInfo && (
          <div style={orderStyles.box}>
            <h1>
              <b>{t('rolesForm.rates')}</b>
            </h1>
            <div style={{ marginTop: '20px' }}>
              <b>{t('orders.delivery')}</b>
              <Divider />
              <div>
                {bagInfo.deliveryRate ? (
                  <p
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {t('comments.rate')}:{' '}
                    <b>
                      {bagInfo.deliveryRate.rate === 5 ? (
                        <span style={{ color: 'green' }}>
                          {t('common.rating.positive')}
                        </span>
                      ) : (
                        <span style={{ color: 'red' }}>
                          {t('common.rating.negative')}
                        </span>
                      )}
                    </b>
                  </p>
                ) : (
                  <span>{t('common.ratings.noRating')}</span>
                )}
              </div>
              <div style={{ marginTop: '10px' }}>
                {bagInfo.deliveryRate ? (
                  bagInfo.deliveryRate.rate === 1 ? (
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {t('clientCall.comment')}:{' '}
                      <i>"{bagInfo.deliveryRate.comment}"</i>
                    </p>
                  ) : (
                    t('common.ratings.noComment')
                  )
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <b>{t('common.menu', 'Menu')}</b>
              <Divider />
              {bagInfo?.items?.length > 0
                ? bagInfo?.items.map((item, index) => {
                    return (
                      <div key={index}>
                        <Accordion
                          active={-1}
                          remove={item.isCustomBought}
                          dishItem={item['@id']}
                          setResignDishItem={setResignDishItem}
                          addToChangedAttributes={addToChangedAttributes}
                          setIsOpenReturnCostRemoveAdditionalDishes={
                            setIsOpenReturnCostRemoveAdditionalDishes
                          }
                          collapses={[
                            {
                              title: item.isCustomBought
                                ? 'Dodatkowe danie: ' + item.mealType.name
                                : item.mealType.name,
                              content: (
                                <div style={{ width: '100%' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p>
                                        {item.dish && item.dish.nameForClient}
                                      </p>
                                    </div>
                                    <p style={{ margin: 0 }}>
                                      {item.rate
                                        ? `${item.rate.rate}/5`
                                        : t('common.ratings.noRating')}
                                    </p>
                                  </div>
                                  <p>
                                    {item.rate
                                      ? item.rate.comment &&
                                        t('common.rating.clientComment')
                                      : t('common.ratings.noComment')}
                                  </p>
                                  <p>
                                    <i>
                                      {item.rate
                                        ? item.rate.comment &&
                                          `"${item.rate.comment}"`
                                        : ''}
                                    </i>
                                  </p>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    );
                  })
                : t('common.ratings.noDishesToRate')}
            </div>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <b>{t('common.ratings.extraComment')}</b>
              <Divider />
              <div>
                {bagInfo.rate ? (
                  <i>"{bagInfo.rate.comment}"</i>
                ) : (
                  t('common.ratings.noComment')
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isEmpty(bagInfo) && (
        <DeleteBagSection
          bagInfo={bagInfo}
          dietInfo={dietInfo}
          classes={classes}
          isDietDay={isDietDay}
          isLoading={isLoading}
          orderStyles={orderStyles}
          setPickerDate={setPickerDate}
          isDisabledDay={isDisabledDay}
          isSelectedDaySubscription={isSelectedDaySubscription}
          renderNotAvailableOptionInSubscriptionTooltip={
            renderNotAvailableOptionInSubscriptionTooltip
          }
          setIsLoading={setIsLoading}
          setCalendarNeedsUpdate={setCalendarNeedsUpdate}
        />
      )}
      <BagChangeModal
        bagChangeModalStatus={bagChangeModal}
        setBagChangeModalStatus={setBagChangeModal}
        bagChanges={bagChanges}
        t={t}
      />
      {addonIdToRemove && (
        <SweetAlert
          warning
          title={
            isSelectedDaySubscriptionIntent
              ? t('menuPlanner.planner.confirmModal.deleteAddonSubscription')
              : t('menuPlanner.planner.confirmModal.alertTitle')
          }
          onConfirm={confirmDeleteAddons}
          onCancel={() => setAddonIdToRemove(null)}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      )}
      <CopyPaymentLinkDialog
        classes={classes}
        history={false}
        isOpened={isOpen}
        paymentLink={paymentLink}
        paymentToPay={paymentToPay}
        setIsOpened={setIsOpen}
      />
      <ModalReturnPoints
        classes={classes}
        history={false}
        isOpened={isOpenReturnCost}
        returnCost={returnCost}
        setIsOpened={setIsOpenReturnCost}
        changeDay={changeDay}
        bagInfo={bagInfo}
      />

      <ModalRemoveAdditionalDishes
        classes={classes}
        isOpened={isOpenRemoveAdditionalDishes}
        setIsOpened={setIsOpenReturnCostRemoveAdditionalDishes}
        changeDay={changeDay}
        bagInfo={bagInfo}
      />
    </div>
  );
};

const enhance = compose(
  withStyles(sweetAlertStyle),
  connect(null, { openSweetToast })
);

export default enhance(SingleDayDetails);
