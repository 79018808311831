import { Component } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { grayColor, whiteColor } from 'assets/jss/material-dashboard-pro-react';

import MySelect from './SelectAll.jsx';

export const ALL_SELECTED_VALUE = '*';

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
      <label
        style={{
          color: props.isFocused ? whiteColor : grayColor[7],
        }}
      >
        {props.label}
      </label>
    </components.Option>
  </div>
);

const allOption = t => ({
  label: t('common.shared.checkAll'),
  value: ALL_SELECTED_VALUE,
});

const ValueContainer = ({ children, ...props }) => {
  const { t } = useTranslation();
  const currentValues = props.getValue();

  let toBeRendered = children;

  if (currentValues.some(val => val.value === allOption(t).value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  const { t } = useTranslation();

  let labelToBeDisplayed = `${props.data.label}, `;

  if (props.data.value === allOption(t).value) {
    labelToBeDisplayed = props.selectProps.selectAllLabel || t('common.all');
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export default class SelectAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
    };
  }

  render() {
    const options = this.props.options.hasOwnProperty('length')
      ? this.props.options.map(option => {
          return {
            label: this.props.customLabel
              ? this.props.customLabel(option)
              : option[this.props.mapBy],
            value: option[this.props.trackBy],
          };
        })
      : [];
    return (
      <MySelect
        className={this.props.className}
        selectAllLabel={this.props.selectAllLabel}
        options={options}
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          MultiValue,
          ValueContainer,
        }}
        isClearable={
          this.props.isClearable !== undefined ? this.props.isClearable : true
        }
        onChange={this.props.handleChange}
        allowSelectAll={
          this.props.allowSelectAll !== undefined
            ? this.props.selectAllLabel
            : true
        }
        value={this.props.optionSelected}
        placeholder={this.props.placeholder || ''}
      />
    );
  }
}
