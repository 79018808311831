import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';
import roles from 'helpers/roles';

import MIME_TYPES from './constants/mimeTypes';
import CombinedReport from './CombinedReport/CombinedReport';

const SORT_RAW_OPTIONS = [
  0, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000,
];

const SORT_OPTIONS = SORT_RAW_OPTIONS.map((option, index) => ({
  value: option,
  label: index === 0 ? 0 : SORT_RAW_OPTIONS.length - index,
}));

const useStyles = makeStyles({
  showCode: { marginBottom: 0, textAlign: 'center' },
  tooltipContent: { marginBottom: 0, cursor: 'help' },
});

const DishCards = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const isUsingV4Codes = useIsUsingV4Codes();

  return (
    <CombinedReport
      name="DISHES_CARD"
      title={t('common.mainMenu.reportsDishCards')}
      url="reports/dishes-card"
      mimeTypes={[MIME_TYPES.pdf, MIME_TYPES.xlsx]}
      role={roles.ROLE_SHOW_REPORT_DISHES_CARD}
      fileName={params =>
        getReportFileNameWithMultiplier(
          params,
          t('common.mainMenu.reportsDishCards')
        )
      }
      useDateRange={true}
      showMultiplier={true}
      validateBrands={false}
      validateDatePicker={true}
      useRecipeTags={true}
      singleSelectRecipeTags={true}
      labelSelectTags={t('reports.selectTags')}
      useZoneCategories={true}
      isSalesMarketLangViewEnabled={true}
      archiveReportNames={['DISHES_CARD', 'DISH_CARDS_XLSX']}
      reportConfiguration={{
        report: 'DishCardCompanyConfiguration',
        fields: [
          {
            size: 12,
            field: 'groupBy',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: 'ID',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.groupBy.id`
                  ),
                },
                {
                  value: 'CODE_V2',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.groupBy.codeV2`
                  ),
                },
              ],
            },
          },
          {
            size: 12,
            name: 'showImage',
            field: 'showImage',
            inputType: 'checkbox',
          },
          {
            size: 12,
            inputProps: {
              content: (
                <p className={classes.showCode}>
                  {t(
                    'configuration.reports.report.DishCardCompanyConfiguration.showCode'
                  )}
                </p>
              ),
            },
            inputType: 'divider',
          },
          ...['V1', 'V2', isUsingV4Codes ? 'V4' : 'V3'].map(version => ({
            size: 4,
            field: `showCode${version}`,
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: true,
                  label: t(`common.yes`),
                },
                {
                  value: false,
                  label: t(`common.no`),
                },
              ],
            },
          })),
          {
            size: 12,
            field: `showUnitColumn`,
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: true,
                  label: t(`common.yes`),
                },
                {
                  value: false,
                  label: t(`common.no`),
                },
              ],
            },
          },
          {
            size: 12,
            field: 'orientation',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: 'Landscape',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.orientation.Landscape`
                  ),
                },
                {
                  value: 'Portrait',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.orientation.Portrait`
                  ),
                },
              ],
            },
          },
          {
            size: 12,
            field: 'codeV1Version',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: ['V1_1', 'V1_2', 'V1_3'].map(option => ({
                value: option,
                label: t(
                  `configuration.reports.report.DishCardCompanyConfiguration.codeV1Version.${option}`
                ),
              })),
            },
          },
          {
            size: 12,
            field: 'wpoColumnMode',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: 'WPO',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPO`
                  ),
                },
                {
                  value: 'WPOM',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPOM`
                  ),
                },
                {
                  value: 'WPO_WPOM',
                  label: t(
                    `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPO_WPOM`
                  ),
                },
              ],
            },
          },
          {
            size: 12,
            inputProps: {
              content: (
                <Tooltip
                  title={
                    <h4>
                      {t(
                        'configuration.reports.report.DishCardCompanyConfiguration.sortBy.help'
                      )}
                    </h4>
                  }
                  placement="bottom"
                >
                  <p className={classes.tooltipContent}>
                    {t(
                      'configuration.reports.report.DishCardCompanyConfiguration.sortBy.title'
                    )}
                  </p>
                </Tooltip>
              ),
            },
            inputType: 'divider',
          },
          {
            size: 4,
            field: 'sortByDietPosition',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 4,
            field: 'sortByMealTypePosition',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 4,
            field: 'sortByCodeV1',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 4,
            field: 'sortBySizePosition',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 4,
            field: 'sortByCodeV2',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 4,
            field: 'sortByClientChanged',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: SORT_OPTIONS,
            },
          },
          {
            size: 12,
            inputProps: {
              content: (
                <Tooltip
                  title={
                    <h4>
                      {t(
                        'configuration.reports.report.DishCardCompanyConfiguration.keyBy.help'
                      )}
                    </h4>
                  }
                  placement="bottom"
                >
                  <p className={classes.tooltipContent}>
                    {t(
                      'configuration.reports.report.DishCardCompanyConfiguration.keyBy.title'
                    )}
                  </p>
                </Tooltip>
              ),
            },
            inputType: 'divider',
          },
          {
            size: 4,
            field: 'keyByDish',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'keyByDiet',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'keyByMealType',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'keyByClientChanged',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'keyByCodeV1',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'roundDecimalPoints',
            inputType: 'number',
          },
          {
            size: 12,
            field: `forceKg`,
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                {
                  value: true,
                  label: t(`common.yes`),
                },
                {
                  value: false,
                  label: t(`common.no`),
                },
              ],
            },
          },
        ],
      }}
    />
  );
};

export default DishCards;
