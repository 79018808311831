import { useTranslation } from 'react-i18next';
import { LocalShipping } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Logistics = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_ZONE_CATEGORY) && {
      name: t('common.mainMenu.zoneCategories'),
      to: '/zone-categories',
      additionalActivationPaths: getPathSuffixes('/zone-categories', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_ZONE) && {
      name: t('common.mainMenu.zones'),
      to: '/zones',
      additionalActivationPaths: getPathSuffixes('/zones', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_ADDRESS) && {
      name: t('common.mainMenu.addresses'),
      to: '/logistics-addresses',
    },
    isGranted(roles.ROLE_SHOW_DRIVER) && {
      name: t('common.mainMenu.drivers'),
      to: '/drivers',
      additionalActivationPaths: getPathSuffixes('/drivers', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_DICTIONARY) && {
      name: t('common.mainMenu.pickupPoints'),
      to: '/pick-up-points',
      additionalActivationPaths: getPathSuffixes('/pick-up-points', [
        'add',
        'edit/:id',
      ]),
    },
    /*   isGranted(roles.ROLE_SHOW_LOGISTIC_ROUTES) && {
      name: t('routePlanner.menuTitle'),
      to: '/route-planner',
      additionalActivationPaths: getPathSuffixes('/route-planner', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_LOGISTIC_ROUTE_MANAGER) && {
      name: t('routeManager.menuTitle'),
      to: '/route-manager',
    }, */
    isGranted(roles.ROLE_SHOW_ADDRESS_REQUESTS) && {
      name: t('common.mainMenu.addressRequests'),
      to: '/address-requests',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.logistics')}
      icon={<LocalShipping />}
      links={links}
    />
  );
};

export default Logistics;
