import defaultState from '../defaultState';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { fetchMealType } from 'actions/MealTypes';
import { fetchMealSizes } from 'actions/MealSizes';
import { openSweetToast } from 'actions/App';
import { post, put, get } from 'helpers/apiHelpers';
import { sortByKey } from 'helpers/helpers';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import FormView from './Form.view';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/styles';
import { compose } from 'redux';
import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';

const regex =
  /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ!@#$%^&*()_+\-=[\]{}\\|;:'",./<>?]*$/;

const useStyles = makeStyles({
  validationErrorToast: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const FormContainer = ({
  match,
  location,
  fetchMealSizes,
  history,
  fetchMealType,
  openSweetToast,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isUsingV4Codes = useIsUsingV4Codes();

  const [state, setState] = useState({
    sizes: [],
    isFetchedData: false,
    mealTypesGoogleFit: { value: 'Niewybrany', key: 'NOTSET' },
    ...defaultState,
  });

  const typeId = match?.params?.id;
  const isEdit = location?.pathname.includes('edit');

  useEffect(async () => {
    const mealCategoriesResponse = await get('/meal-categories');
    await setState(prevState => ({
      ...prevState,
      mealCategoryOptions: mealCategoriesResponse['hydra:member'],
    }));

    fetchMealSizes().then(response => {
      setState(prev => ({
        ...prev,
        sizes: sortByKey(response, 'position'),
        isFetchedData: true,
      }));
    });
  }, []);

  useEffect(() => {
    isEdit &&
      fetchMealType(typeId).then(type => {
        if (state.sizes.length === 0) {
          return;
        }
        const copySizeFromState = [...state.sizes];

        type.sizes.forEach(element => {
          const indexToChange = copySizeFromState.findIndex(
            el => el['@id'] === element.size['@id']
          );
          copySizeFromState[indexToChange].calories = element.calories;
          copySizeFromState[indexToChange].checked = true;
          copySizeFromState[indexToChange].disabled = true;
          copySizeFromState[indexToChange]['mealTypeSize'] = element['@id'];
        });

        setState(prev => ({
          ...prev,
          name: type.name,
          shortName: type.shortName,
          code: type.code,
          mealCategory: type.mealCategory,
          position: type.position,
          sizes: copySizeFromState,
          codeV4: type?.codeV4 || '',
          mealTypesGoogleFit:
            type.googleFitType === null
              ? { value: 'Niewybrany', key: 'NOTSET' }
              : type.googleFitType,
        }));
      });
  }, [state.isFetchedData]);

  const handleChangePrefixV4 = event => {
    if (!regex.test(event.target.value)) {
      toast.error(
        <Trans
          i18nKey="form.fields.mealTypes.prefix_v4.validationError"
          components={{
            span: <span />,
            div: <div className={classes.validationErrorToast} />,
          }}
        />
      );
      return;
    }
    handleInputChange(event);
  };

  const handleInputChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleToggle = (index, disabled) => {
    const { sizes } = state;

    if (disabled) {
      return toast.info(t('form.cannotTurnOff'));
    }

    setState(prev => ({
      ...prev,
      sizes: update(sizes, {
        [index]: {
          checked: { $set: !sizes[index].checked },
          calories: {
            $set:
              state.sizes[index].checked === false ? sizes[index].calories : '',
          },
        },
      }),
    }));
  };

  const handleCalories = (index, event) => {
    const { sizes } = state;
    setState(prev => ({
      ...prev,
      sizes: update(sizes, {
        [index]: {
          calories: { $set: event.target.value },
        },
      }),
    }));
  };

  const validateForm = () => {
    const prefixValidator = !isUsingV4Codes || state.codeV4 !== '';

    return (
      state.name &&
      state.code !== '' &&
      state.position !== '' &&
      prefixValidator
    );
  };

  const validateCalories = () => {
    if (!state.sizes.some(size => size.checked)) {
      return true;
    }

    return state.sizes.some(size => size.checked && !size.calories);
  };

  const handleSubmit = async () => {
    if (validateCalories()) {
      return toast.error(t('form.fillInAllCalories'));
    }

    if (!validateForm()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }

    const data = {
      name: state.name,
      shortName: state.shortName,
      position: parseInt(state.position),
      code: state.code,
      mealCategory: state.mealCategory?.['@id'] ?? state.mealCategory ?? null,
      sizes: state.sizes
        .reduce((result, element) => {
          if (element.checked === true) {
            result.push(element);
          }
          return result;
        }, [])
        .map(el => {
          let tmp = {
            size: el['@id'],
            calories: parseInt(el.calories),
          };

          if (el.mealTypeSize) {
            tmp['@id'] = el.mealTypeSize;
          }

          return tmp;
        }),
      googleFitType:
        state.mealTypesGoogleFit.key === 'NOTSET'
          ? 'UNKNOWN'
          : state.mealTypesGoogleFit.key,
      codeV4: state.codeV4,
    };

    try {
      isEdit
        ? await put(`/meal-types/${typeId}`, data)
        : await post('/meal-types', data);

      setState(prev => ({
        ...prev,
        errors: {},
      }));
      if (isEdit) {
        openSweetToast({
          style: { display: 'block' },
          content: (
            <div style={{ textAlign: 'left' }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info1'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info2'),
                }}
              />
              <b
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info3'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info4'),
                }}
              />
              <b
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info5'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('mealTypes.save.info6'),
                }}
              />
            </div>
          ),
          showCancel: false,
        });
      }
      history.push('/admin/types');
    } catch ({ response: { data: dataResponse } }) {
      if (dataResponse.hasOwnProperty('violations')) {
        const tempErrors =
          dataResponse.violations.reduce(
            (acc, { message, propertyPath }) => ({
              ...acc,
              [propertyPath]: message,
            }),
            {}
          ) || {};

        setState(prev => ({
          ...prev,
          errors: tempErrors,
        }));
      } else {
        return toast.error(
          `${t('form.somethingWentWrong')} ${t('form.tryAgainLater')}`
        );
      }
    }
  };

  return (
    <FormView
      state={state}
      setState={setState}
      typeId={typeId}
      isEdit={isEdit}
      handleChangePrefixV4={handleChangePrefixV4}
      handleInputChange={handleInputChange}
      handleToggle={handleToggle}
      handleCalories={handleCalories}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = state => ({
  mealSizes: state.MealSizes.mealSizes,
  mealType: state.MealTypes.mealType,
});

const mapDispatchToProps = {
  fetchMealSizes,
  fetchMealType,
  openSweetToast,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhance(FormContainer);
