import { useTranslation } from 'react-i18next';
import { Book } from '@material-ui/icons';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Dictionaries = () => {
  const { t } = useTranslation();

  const links = [
    {
      name: t('common.mainMenu.allergens'),
      to: '/allergens',
      additionalActivationPaths: getPathSuffixes('/allergens', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.department'),
      to: '/departments',
      additionalActivationPaths: getPathSuffixes('/departments', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.wholesalers'),
      to: '/wholesalers',
      additionalActivationPaths: getPathSuffixes('/wholesalers', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.units'),
      to: '/units',
      additionalActivationPaths: getPathSuffixes('/units', ['add', 'edit/:id']),
    },
    {
      name: t('common.mainMenu.mealCategory'),
      to: '/meal-categories',
      additionalActivationPaths: getPathSuffixes('/meal-categories', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.ingredientCategories'),
      to: '/ingredient-categories',
      additionalActivationPaths: getPathSuffixes('/ingredient-categories', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.contractors'),
      to: '/contractors',
      additionalActivationPaths: getPathSuffixes('/contractors', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.kitchen'),
      to: '/kitchens',
      additionalActivationPaths: getPathSuffixes('/kitchens', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.stowage'),
      to: '/stowages',
      additionalActivationPaths: getPathSuffixes('/stowages', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.sex'),
      to: '/sexes',
      additionalActivationPaths: getPathSuffixes('/sexes', ['add', 'edit/:id']),
    },
    {
      name: t('common.mainMenu.containers'),
      to: '/containers',
      additionalActivationPaths: getPathSuffixes('/containers', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.ecoContainers'),
      to: '/eco-containers',
      additionalActivationPaths: getPathSuffixes('/eco-containers', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.servingSuggestions'),
      to: '/serving-suggestions',
      additionalActivationPaths: getPathSuffixes('/serving-suggestions', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.howKnow'),
      to: '/how-knows',
      additionalActivationPaths: getPathSuffixes('/how-knows', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.tagsForClients'),
      to: '/client-tags',
      additionalActivationPaths: getPathSuffixes('/client-tags', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.tagsForProduction'),
      to: '/tags',
      additionalActivationPaths: getPathSuffixes('/tags', ['add', 'edit/:id']),
    },
    {
      name: t('common.mainMenu.tagsForClientsSms'),
      to: '/sms-client-tags',
      additionalActivationPaths: getPathSuffixes('/sms-client-tags', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.bagContainers'),
      to: '/bag-containers',
      additionalActivationPaths: getPathSuffixes('/bag-containers', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.deliveryTypes'),
      to: '/delivery-types',
      additionalActivationPaths: getPathSuffixes('/delivery-types', [
        'add',
        'edit/:id',
      ]),
    },
    {
      name: t('common.mainMenu.sources'),
      to: '/sources',
      additionalActivationPaths: getPathSuffixes('/sources', [
        'add',
        'edit/:id',
      ]),
    },
  ].sort((linkA, linkB) => linkA.name.localeCompare(linkB.name));

  return (
    <SidebarLinks
      name={t('common.mainMenu.dictionariesCaption')}
      icon={<Book />}
      links={links}
    />
  );
};

export default Dictionaries;
