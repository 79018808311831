import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { Tooltip, FormControl, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import getReportFileName from 'helpers/getReportFileName';
import roles from 'helpers/roles';
import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';
import GridContainer from 'components/Grid/GridContainer';
import Datetime from 'components/Form/DateTme';
import GridItem from 'components/Grid/GridItem';
import {
  choiceTrueFalseField,
  ReportLabelDivider,
} from 'components/Report/ReportConfigurator';

import BaseBrandReport from './BaseBrandReport';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  tooltipContent: { marginBottom: 0, cursor: 'help' },
  container: { padding: '12px' },
});

const DishLabels = () => {
  const { t } = useTranslation();

  const [packedDate, setPackedDate] = useState(null);
  const [shouldBeConsumedTo, setShouldBeConsumedTo] = useState(null);

  const classes = useStyles();

  const isUsingV4Codes = useIsUsingV4Codes();

  const title = t('reports.reportDishLabels');

  return (
    <BaseBrandReport
      title={title}
      excelUrl="reports/dish-stickers"
      pdfUrl="reports/dish-stickers-pdf"
      fileName={getReportFileName(title)}
      role={roles.ROLE_SHOW_REPORT_DISH_STICKERS}
      pdfAvailable={true}
      additionalParams={{
        packedDate,
        shouldBeConsumedTo,
      }}
      excelAvailable={true}
      useMealCategories={true}
      useZoneCategories={true}
      isSalesMarketLangViewEnabled={true}
      archiveReportNames={['DISH_STICKER']}
      dateType="single"
      reportConfiguration={{
        report: 'DishStickers',
        fields: [
          {
            size: 12,
            field: 'dishColumns',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'dishCharsPerColumn',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'ingredientsColumns',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'ingredientsCharsPerColumn',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'allergensColumns',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'allergensCharsPerColumn',
            inputType: 'number',
          },
          {
            size: 12,
            field: 'showIngredientMode',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                'SHOW_INGREDIENT_BASIC_WITHOUT_PERCENTAGE',
                'SHOW_INGREDIENT_SHOP_WITHOUT_PERCENTAGE',
                'SHOW_INGREDIENT_SHOP_WITH_PERCENTAGE',
              ].map(key => ({
                value: key,
                label: t(
                  `configuration.reports.report.DishStickers.showIngredientMode.${key}`
                ),
              })),
            },
          },
          {
            size: 12,
            field: 'mode',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: ['COLUMN', 'ROWS'].map(key => ({
                value: key,
                label: t(
                  `configuration.reports.report.DishStickers.mode.${key}`
                ),
              })),
            },
          },
          {
            size: 12,
            field: 'codeV1Version',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: ['V1_1', 'V1_2', 'V1_3'].map(option => ({
                value: option,
                label: t(
                  `configuration.reports.report.DishStickers.codeV1Version.${option}`
                ),
              })),
            },
          },
          {
            size: 12,
            field: 'dietColumnMode',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: ['DIET_RAW', 'DIET_OVERRIDDEN_BY_CHOICE'].map(
                option => ({
                  value: option,
                  label: t(
                    `configuration.reports.report.DishStickers.dietColumnMode.${option}`
                  ),
                })
              ),
            },
          },
          {
            size: 12,
            inputProps: {
              content: (
                <Tooltip
                  title={
                    <h4>
                      {t(
                        'configuration.reports.report.DishStickers.sortBy.help'
                      )}
                    </h4>
                  }
                  placement="bottom"
                >
                  <p className={classes.tooltipContent}>
                    {t(
                      'configuration.reports.report.DishStickers.sortBy.title'
                    )}
                  </p>
                </Tooltip>
              ),
            },
            inputType: 'divider',
          },
          {
            size: 4,
            field: 'sortByDietPosition',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByMealTypePosition',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortBySizeName',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByCodeV1',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByCodeV2',
            inputType: 'number',
          },
          ...(isUsingV4Codes
            ? [
                {
                  size: 4,
                  field: 'sortByCodeV4',
                  inputType: 'number',
                },
              ]
            : []),
          {
            size: 4,
            field: 'sortByClientChanged',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByClientChangedBag',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByContainerType',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByDietWorkName',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByCalorificWorkName',
            inputType: 'number',
          },
          {
            size: 4,
            field: 'sortByVariantShortName',
            inputType: 'number',
          },
          {
            size: 12,
            inputProps: {
              content: <ReportLabelDivider label="PDF" />,
            },
            inputType: 'divider',
          },
          {
            size: 12,
            field: 'pdfShowIngredients',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                'HIDE_INGREDIENT',
                'SHOW_INGREDIENT_BASIC_WITHOUT_PERCENTAGE',
                'SHOW_INGREDIENT_SHOP_WITHOUT_PERCENTAGE',
                'SHOW_INGREDIENT_SHOP_WITH_PERCENTAGE',
              ].map(key => ({
                value: key,
                label: t(
                  `configuration.reports.report.DishStickers.pdfShowIngredients.${key}`
                ),
              })),
            },
          },
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowDiet',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowMealType',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowServingSuggestion',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowDayOfWeek',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowContainerType',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowAllergen',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowMacroIngredients',
          }),
          choiceTrueFalseField({
            size: 4,
            t,
            field: 'pdfShowCodeV2',
          }),
        ],
      }}
    >
      <GridContainer className={classes.container}>
        <GridItem sm={12}>
          <h4 className={classes.cardIconTitle}>{t('reports.defaults')}</h4>
        </GridItem>
        <GridItem sm={12} md={6} lg={3}>
          <FormLabel className={classes.labelHorizontal}>
            {t('reports.packedDate')}
          </FormLabel>
          <FormControl fullWidth={true}>
            <Datetime
              className="input-datetime--packed-date"
              timeFormat={false}
              dateFormat={Moment.localeData().longDateFormat('L')}
              closeOnSelect={true}
              value={new Moment(packedDate)}
              onChange={e => {
                setPackedDate(e.format('YYYY-MM-DD'));
              }}
              onClear={() => {
                setPackedDate(null);
              }}
              showClear={packedDate !== null}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem sm={12} md={6} lg={3}>
          <FormLabel className={classes.labelHorizontal}>
            {t('reports.shouldBeConsumedTo')}
          </FormLabel>
          <FormControl fullWidth={true}>
            <Datetime
              className="input-datetime--consumed-date"
              timeFormat={false}
              dateFormat={Moment.localeData().longDateFormat('L')}
              closeOnSelect={true}
              value={new Moment(shouldBeConsumedTo)}
              onChange={e => {
                setShouldBeConsumedTo(e.format('YYYY-MM-DD'));
              }}
              onClear={() => {
                setShouldBeConsumedTo(null);
              }}
              showClear={shouldBeConsumedTo !== null}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
    </BaseBrandReport>
  );
};

export default DishLabels;
