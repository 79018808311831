import { useState, useEffect } from 'react';
import _ from 'lodash';

import { isGranted } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import GridItem from 'components/Grid/GridItem';
import LogView from 'components/History/LogView';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AdditionalPricesRow from './AdditionalPricesRow';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...validationFormsStyle,
  ...buttonsStyle,
});

const AdditionalPrices = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [additionalPrices, setAdditionalPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const findAdditionalPrices = ({
    additionLPrices,
    el,
    path,
    value = null,
  }) => {
    return _.get(
      additionLPrices.find(e => e.additionalPriceType === el['@id']),
      path,
      value
    );
  };

  const getData = () => {
    try {
      Promise.all([
        get('/additional-price-types', { pagination: false }),
        get('/additional-prices', { pagination: false }),
      ]).then(([additionalPriceTypesResponse, additionalPricesResponse]) => {
        const additionLPrices = additionalPricesResponse['hydra:member'];
        const additionalPriceTypes = additionalPriceTypesResponse[
          'hydra:member'
        ].map(el => ({
          ...el,
          id: findAdditionalPrices({ additionLPrices, el, path: 'id' }),
          additionalPriceType: el['@id'],
          discount: findAdditionalPrices({
            additionLPrices,
            el,
            path: 'discount',
            value: '',
          }),
          vatRate: findAdditionalPrices({
            additionLPrices,
            el,
            path: 'vatRate',
          }),
          isPercentage: findAdditionalPrices({
            additionLPrices,
            el,
            path: 'isPercentage',
            value: false,
          }),
        }));

        setAdditionalPrices(additionalPriceTypes);
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const findCurrentPriceIndex = id =>
    additionalPrices.findIndex(point => point['@id'] === id);

  const handleChange = (e, point) => {
    try {
      const { name, value } = e.target;
      if (name === 'discount' && isNaN(value)) {
        toast.error(t('common.shared.feeFieldMustBeNumber'));
        return;
      }
      const updatedPrices = [...additionalPrices];

      const index = findCurrentPriceIndex(point['@id']);
      updatedPrices[index][name] = value;

      setAdditionalPrices(updatedPrices);
    } catch (error) {
      console.error('Error handling change:', error);
    }
  };

  const saveRow = point => {
    try {
      const index = findCurrentPriceIndex(point['@id']);
      const currentEl = additionalPrices[index];

      if (currentEl.discount === '') {
        toast.error(t('common.shared.fillFeeField'));
        return;
      }

      if (isNaN(currentEl.discount)) {
        toast.error(t('common.shared.feeFieldMustBeNumber'));
        return;
      }

      const data = {
        discount: parseFloat(currentEl.discount),
        vatRate: parseInt(currentEl.vatRate),
        isPercentage: currentEl.isPercentage,
        additionalPriceType: currentEl.additionalPriceType,
      };

      const action = currentEl.id ? put : post;
      const url = currentEl.id
        ? `/additional-prices/${currentEl.id}`
        : '/additional-prices';

      action(url, data).then(() => {
        getData();
        toast.success(t('success.chargeSaved'));
      });
    } catch (error) {
      console.error('Error saving row:', error);
    }
  };

  return (
    <form
      style={{
        opacity: isLoading ? 0 : 1,
        transition: '0.3s all',
      }}
    >
      {additionalPrices.length > 0 && (
        <Card>
          <CardBody>
            <GridItem md={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('common.shared.nameOfTheFee')}</TableCell>
                    <TableCell>{t('common.shared.feeType')}</TableCell>
                    <TableCell>{t('common.shared.feePerDay')}</TableCell>
                    <TableCell>{t('common.shared.vat')}</TableCell>
                    {isGranted(ROLE_EDIT_COST) && <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalPrices.map(point => {
                    if (point.systemValue !== 'CHANGE_MENU') {
                      return null;
                    }

                    return (
                      <AdditionalPricesRow
                        key={point['@id']}
                        point={point}
                        classes={classes}
                        handleChange={handleChange}
                        saveRow={saveRow}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
            <GridItem md={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('common.shared.nameOfTheFee')}</TableCell>
                    <TableCell>{t('common.shared.feeType')}</TableCell>
                    <TableCell>{t('common.shared.feePerPiece')}</TableCell>
                    <TableCell>{t('common.shared.vat')}</TableCell>
                    {isGranted(ROLE_EDIT_COST) && <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalPrices.map(point => {
                    if (point.systemValue === 'CHANGE_MENU') {
                      return null;
                    }

                    return (
                      <AdditionalPricesRow
                        key={point['@id']}
                        point={point}
                        classes={classes}
                        handleChange={handleChange}
                        saveRow={saveRow}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
          </CardBody>
        </Card>
      )}
      <LogView iri={'additional-prices'} />
    </form>
  );
};

export default AdditionalPrices;
