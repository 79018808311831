import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from '../../../helpers/helpers';
import { ROLE_CREATE_NOTIFICATION } from '../../../helpers/roles';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const List = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.messagesList')}>
      {isGranted(ROLE_CREATE_NOTIFICATION) && (
        <FormControlButtons
          handleSubmit={newSize}
          submitText={t('form.menuPlanning.addMessages') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/notifications"
        reportName={'notification'}
        columns={columnConfig}
        role="NOTIFICATION"
        minRows={20}
      />
    </AdminTable>
  );
};

export default withTranslation()(List);
