import { useTranslation } from 'react-i18next';
import { BusinessCenter } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Offer = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_SIZE) && {
      name: t('common.mainMenu.sizes'),
      to: '/sizes',
      additionalActivationPaths: getPathSuffixes('/sizes', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_MEAL_TYPE) && {
      name: t('common.mainMenu.typesOfMeals'),
      to: '/types',
      additionalActivationPaths: getPathSuffixes('/types', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_DIET) && {
      name: t('common.mainMenu.diets'),
      to: '/diets',
      additionalActivationPaths: getPathSuffixes('/diets', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_VARIANT) && {
      name: t('common.mainMenu.variants'),
      to: '/variants',
      additionalActivationPaths: getPathSuffixes('/variants', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_PACKAGE) && {
      name: t('common.mainMenu.dietFilters'),
      to: '/packages',
      additionalActivationPaths: getPathSuffixes('/packages', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_PACKAGE_LINE_FILTER) && {
      name: t('common.mainMenu.dietLineFilters'),
      to: '/filter-line-diets',
      additionalActivationPaths: getPathSuffixes('/filter-line-diets', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS) && {
      name: t('massAction.dietElements.title'),
      to: '/mass-actions-diet-elements',
      additionalActivationPaths: getPathSuffixes(
        '/mass-actions-diet-elements',
        ['add']
      ),
    },
    isGranted(roles.ROLE_SHOW_ADDON) && {
      name: t('common.mainMenu.addonsSubmenu'),
      to: '/addons',
      additionalActivationPaths: getPathSuffixes('/addons', [
        'add',
        'edit/:id',
        'settings',
      ]),
    },
    isGranted(roles.ROLE_SHOW_ADDON) && {
      name: t('common.mainMenu.addonsCategories'),
      to: '/addon-categories',
      additionalActivationPaths: getPathSuffixes('/addon-categories', [
        'add',
        'edit/:id',
      ]),
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.offer')}
      icon={<BusinessCenter />}
      links={links}
    />
  );
};

export default Offer;
