import AdminTable from 'layouts/AdminTable';

import defaultState from './defaultState';
import { connect } from 'react-redux';
import { fetchMealSize } from 'actions/MealSizes';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import { post, put } from 'helpers/apiHelpers';
import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';

import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  validationErrorToast: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: { marginLeft: '5px' },
});

const Form = ({ match, location, history, fetchMealSize }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isUsingV4Codes = useIsUsingV4Codes();

  const [state, setState] = useState(defaultState);

  const sizeId = match?.params?.id;
  const isEdit = location?.pathname.includes('edit');

  useEffect(() => {
    if (isEdit) {
      fetchMealSize(sizeId).then(response => {
        setState(prev => ({
          ...prev,
          name: response.name,
          nameForClient: response.nameForClient,
          position: response.position,
          portionDivision: response.portionDivision,
          codeV4: response?.codeV4 || '',
        }));
      });
    }
  }, []);

  const handleInputChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleChangePrefixV4 = event => {
    const regex =
      /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ!@#$%^&*()_+\-=[\]{}\\|;:'",./<>?]*$/;

    if (!regex.test(event.target.value)) {
      toast.error(
        <Trans
          i18nKey="form.fields.sizes.prefix_v4.validationError"
          components={{
            s: <span />,
            d: <div className={classes.validationErrorToast} />,
          }}
        />
      );
      return;
    }
    handleInputChange(event);
  };

  const validateForm = () => {
    const prefixValidator = !isUsingV4Codes || state.codeV4 !== '';

    return (
      state.name &&
      state.nameForClient &&
      state.position !== '' &&
      state.portionDivision !== '' &&
      prefixValidator
    );
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }
    const data = {
      name: state.name,
      nameForClient: state.nameForClient,
      position: parseInt(state.position),
      portionDivision: parseInt(state.portionDivision),
      codeV4: state.codeV4,
    };

    const action = isEdit
      ? put(`/sizes/${sizeId}`, data)
      : post('/sizes', data);

    action.then(() => history.push('/admin/sizes'));
  };

  return (
    <AdminTable title={t('form.addNewSize')}>
      <FormTextInput
        label={t('form.field.sizeName') + '*'}
        classes={classes}
        name="name"
        value={state.name}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <FormTextInput
        label={t('form.field.nameForClient') + '*'}
        classes={classes}
        name="nameForClient"
        value={state.nameForClient}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <FormTextInput
        label={
          <>
            {t('form.field.sizePrefixV4')}
            {isUsingV4Codes && '*'}
            <Tooltip
              className={classes.tooltip}
              title={
                <div>
                  <h4>
                    <Trans
                      i18nKey="form.fields.sizes.prefix_v4.tooltip"
                      components={{
                        l: <li />,
                        u: <ul />,
                        p: <p />,
                      }}
                    />
                  </h4>
                </div>
              }
              placement="right"
            >
              <Info fontSize="small" />
            </Tooltip>
          </>
        }
        maxLength={5}
        classes={classes}
        name="codeV4"
        value={state.codeV4}
        handleChange={handleChangePrefixV4}
        inputSize={4}
      />
      <FormTextInput
        label={t('form.field.order') + '*'}
        classes={classes}
        name="position"
        value={state.position}
        type="number"
        handleChange={handleInputChange}
        inputSize={2}
      />
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/sizes"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const mapStateToProps = state => ({
  mealSize: state.MealSizes.mealSize,
  loading: state.MealSizes.loading,
});

const mapDispatchToProps = dispatch => ({
  fetchMealSize: id => dispatch(fetchMealSize(id)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Form)
);
