import { useTranslation } from 'react-i18next';
import { BarChart } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const AnalyticalReports = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_REPORT_INCOME_SUMMARY) && {
      name: t('common.mainMenu.reportsIncomeSummary'),
      to: '/income-summary-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_FOOD_COST_BAGS) && {
      name: t('common.mainMenu.reportsFoodCostBags'),
      to: '/bags-food-cost-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_FOOD_COST) && {
      name: t('common.mainMenu.reportsFoodCost'),
      to: '/food-cost-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ORDERED_DIET) && {
      name: t('common.mainMenu.reportOrderedDiets'),
      to: '/ordered-diets-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES) && {
      name: t('common.mainMenu.reportsDiscountCodes'),
      to: '/usage-of-discount-codes-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP) && {
      name: t('common.mainMenu.reportsMoneyboxTopUp'),
      to: '/money-box-top-up-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION) && {
      name: t('common.mainMenu.reportsIncomingSubscriptions'),
      to: '/incoming-subscriptions-report',
    },
    /*  isGranted(roles.ROLE_SHOW_REPORT_SENT_SMS) && {
      name: t('reports.reportSentSms'),
      to: '/sent-sms-report',
    }, */
    isGranted(roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS) && {
      name: t('common.mainMenu.macroIngredientsReport'),
      to: '/macro-ingredients-report',
    },
    /*     isGranted(roles.ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES) && {
      name: t('common.mainMenu.employeesReport'),
      to: '/employees-report',
    },
    isGranted(roles.ROLE_SHOW_CLIENT_CALL) && {
      name: t('common.mainMenu.reportsBilling'),
      to: '/billing-report',
    }, */
    isGranted(roles.ROLE_SHOW_REPORT_OVERPRODUCTION) && {
      name: t('common.mainMenu.reportsOverProduction'),
      to: '/overproduction-report',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.caterings.analitics')}
      icon={<BarChart />}
      links={links}
    />
  );
};

export default AnalyticalReports;
