import { useState, useEffect } from 'react';
import AdminTable from 'layouts/AdminTable';
import moment from 'moment';
import { useRouteMatch, useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { fetchMessage } from 'actions/Messages';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { post, put } from 'helpers/apiHelpers';
import Datetime from 'react-datetime';

import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  ...buttonsStyle,
  ...extendedFormsStyle,
  label: {
    margin: '10px 0 0',
    color: 'rgba(0,0,0,.46)',
    fontWeight: '400',
  },
  dateTimeWrapper: {
    width: '140px',
  },
});

const formatData = 'DD-MM-YYYY';
const reverseFormatDate = 'YYYY-MM-DD';

const Form = ({ fetchMessage }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const [date, setDate] = useState('');
  const [content, setContent] = useState('');

  const sizeId = match.params.id;
  const isEdit = location.pathname.includes('edit');

  useEffect(() => {
    if (sizeId) {
      fetchMessage(sizeId).then(response => {
        const date = moment(response.date).format(formatData);
        const content = response.content;
        setDate(date);
        setContent(content);
      });
    }
  }, []);

  const handleInputChange = event => {
    const content = event.target.value;
    setContent(content);
  };

  const validateForm = () => {
    return content && date !== '';
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }
    const data = {
      content: content,
      date: moment(date, formatData).format(reverseFormatDate),
    };

    const action = isEdit
      ? put(`/notifications/${sizeId}`, data)
      : post('/notifications', data);

    action.then(() => history.push('/admin/messages'));
  };

  return (
    <AdminTable title={t('form.addNewMessage')}>
      <p className={classes.label}>{t('form.messageContent')}*</p>
      <SimpleWysiwyg handleChange={handleInputChange} value={content} />
      <div className={classes.dateTimeWrapper}>
        <p className={classes.label}>{t('form.displayDate')}*</p>
        <Datetime
          timeFormat={false}
          closeOnSelect={true}
          value={date}
          onChange={selectedDate => setDate(selectedDate.format(formatData))}
          inputProps={{
            placeholder: t('form.selectDate'),
            readOnly: true,
          }}
        />
      </div>
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/messages"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const mapStateToProps = state => {
  return {
    mealSize: state.MealSizes.mealSize,
    loading: state.MealSizes.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMessage: id => dispatch(fetchMessage(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
