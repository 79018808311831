import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_SIZE } from 'helpers/roles';

import { withTranslation } from 'react-i18next';

const List = ({ history, classes, t }) => {
  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.sizesList')}>
      {isGranted(ROLE_CREATE_SIZE) && (
        <FormControlButtons
          classes={classes}
          handleSubmit={newSize}
          submitText={t('list.addSize') + ' +'}
        />
      )}
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        defaultSorted={[{ id: 'position', desc: false }]}
        url="sizes"
        reportName={'size'}
        columns={columnConfig}
        defaultHiddenColumns={['codeV4']}
        role="SIZE"
      />
    </AdminTable>
  );
};

export default withTranslation()(withStyles(buttonsStyle)(List));
