import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ROLE_EDIT_COST } from 'helpers/roles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { isGranted } from 'helpers/helpers';
import CustomInput from 'components/CustomInput/CustomInput';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  tableCell: { width: '250px' },
});

const translateDiscountElementTypes = t => ({
  1: t('common.shared.percentage'),
  0: t('common.shared.amount'),
});

const translateOptions = t => ({
  CHANGE_MENU: t('additionalPrices.changeMenu'),
  ECO_CONTAINER: t('additionalPrices.ecoContainer'),
});

const AdditionalPricesRow = ({ point, handleChange, saveRow }) => {
  const { t } = useTranslation();
  const options = translateOptions(t);
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {options[point.systemValue] || point.value}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {translateDiscountElementTypes(t)[point.isPercentage ? 1 : 0]}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: 'text',
            placeholder: t('common.shared.typeValue'),
            name: 'discount',
            value: point.discount,
            onChange: event => handleChange(event, point),
          }}
        />
      </TableCell>
      <TableCell>
        {point.systemValue.includes('CONTAINER') && (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: 'number',
              placeholder: t('common.shared.typeValue'),
              name: 'vatRate',
              value: point.vatRate,
              onChange: event => handleChange(event, point),
            }}
          />
        )}
      </TableCell>
      {isGranted(ROLE_EDIT_COST) && (
        <TableCell>
          <FormControlButtons
            handleSubmit={() => saveRow(point)}
            submitText={t('common.shared.save')}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default AdditionalPricesRow;
