import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
  wrapper: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #D2D2D2',
  },
  inputParent: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    width: '95%',
    border: 'none',
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    background: 'white',
  },
  icon: {
    color: '#BDBDBD',
    marginTop: '6px',
  },
});

const PasswordInput = ({
  label,
  name,
  value,
  showPasswordKey,
  autoComplete,
  state,
  setState,
  focusKey,
  repeatInput,
}) => {
  const classes = useStyles();
  const blockedCharPattern = /[\^&\*\(\)_\+\-=\[\]\{};':"\\|,.<>\/\?`~!]/;

  const modifyState = (key, value) => {
    setState(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const getBorderBottom = () => {
    const { focusedPasswordRepeat, plainPassword, plainPasswordMatch } = state;

    if (!focusedPasswordRepeat || plainPasswordMatch === '') {
      return '1px solid #D2D2D2';
    }

    return plainPassword === plainPasswordMatch
      ? '2px solid #4CAF50'
      : '2px solid #F44336';
  };

  return (
    <div
      className={classes.wrapper}
      style={
        repeatInput && {
          borderBottom: getBorderBottom(),
        }
      }
    >
      <label htmlFor={name}>{label}</label>
      <div className={classes.inputParent}>
        <input
          type={state[showPasswordKey] ? 'text' : 'password'}
          className={classes.input}
          autoComplete={autoComplete}
          name={name}
          placeholder=" "
          value={value}
          onChange={e => {
            if (!blockedCharPattern.test(e.target.value)) {
              modifyState(name, e.target.value);
            }
          }}
          onFocus={() => focusKey && modifyState(focusKey, true)}
          onBlur={() => focusKey && modifyState(focusKey, false)}
        />
        <button
          type="button"
          onClick={() =>
            modifyState([showPasswordKey], !state[showPasswordKey])
          }
          className={classes.button}
        >
          {state[showPasswordKey] ? (
            <VisibilityOutlined className={classes.icon} />
          ) : (
            <VisibilityOffOutlined className={classes.icon} />
          )}
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
