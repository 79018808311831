import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '5px',
    color: 'inherit',
    fontWeight: '500',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  icon: {
    fontSize: '16px',
  },
});

const InfoLink = ({ link, text = '' }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <a
      className={classes.link}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text || t('common.findOutMore')}
      <OpenInNewIcon className={classes.icon} />
    </a>
  );
};

InfoLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default InfoLink;
