import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import PayUInfoWithTooltip from '../PayUInfoWithTooltip';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    gap: '4px',
    marginLeft: '15px',
  },
  box: {
    width: '55%',
    height: '100%',
    marginLeft: 'auto',
    background: 'RGB(0,172,193)',
  },
});

const PayUModule = ({ classes: propClasses, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayUModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <h3 className={classes.title}>
            {t('paymentType.title.PayU', 'Ustawienia PayU')}{' '}
            <PayUInfoWithTooltip />
          </h3>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <NumberFormat
          label="Protokół OAuth - client_id:"
          classes={propClasses}
          customInput={FormTextInput}
          value={module.clientId}
          onChange={e =>
            handleModuleUpdate({ ...module, clientId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <NumberFormat
          label="Id punktu płatności (pos_id):"
          classes={propClasses}
          customInput={FormTextInput}
          value={module.posId}
          onChange={e =>
            handleModuleUpdate({ ...module, posId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Protokół OAuth - client_secret:"
          classes={propClasses}
          name="payUClientSecret"
          value={module.clientSecret}
          handleChange={e =>
            handleModuleUpdate({ ...module, clientSecret: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Drugi klucz (MD5):"
          classes={propClasses}
          name="payUmd5Key"
          value={module.md5Key}
          handleChange={e =>
            handleModuleUpdate({ ...module, md5Key: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Google Pay - Merchant ID:"
          classes={propClasses}
          name="payUGooglePayMerchantId"
          value={module.googlePayMerchantId}
          handleChange={e => {
            handleModuleUpdate({
              ...module,
              googlePayMerchantId: e.target.value,
            });
          }}
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayUModule;
