export const modulesNames = {
  Addons: 'Addons',
  AuthServices: 'AuthServices',
  BlueMedia: 'BlueMedia',
  ConfigClientPanel: 'ConfigClientPanel',
  FacebookPixel: 'FacebookPixel',
  Fakturownia: 'Fakturownia',
  FakturowniaWarehouse: 'FakturowniaWarehouse',
  Fonts: 'Fonts',
  Goodspeed: 'Goodspeed',
  GoogleAnalytics: 'GoogleAnalytics',
  GoogleTagManager: 'GoogleTagManager',
  HotJar: 'HotJar',
  PayU: 'PayU',
  PayPo: 'PayPo',
  SalesManago: 'SalesManago',
  Stripe: 'Stripe',
  MyLead: 'MyLead',
  PaymentModesCopy: 'PaymentModesCopy',
  ClickOnMetrics: 'ClickOnMetrics',
  PollsterTrack: 'PollsterTrack',
  PayNow: 'PayNow',
  AdditionalPayment: 'AdditionalPayment',
  GetResponse: 'GetResponse',
  Ipresso: 'Ipresso',
  UserCom: 'UserCom',
  Bitrix: 'Bitrix24',
  ItemPaymentTypes: 'ItemPaymentTypes',
  TrustedShops: 'TrustedShops',
  Thulium: 'Thulium',
  Omniconvert: 'Omniconvert',
  ScanAndSort: 'ScanAndSort',
  Foodsi: 'Foodsi',
};

export const modulesDefaultState = {
  Bitrix: {
    '@type': modulesNames.Bitrix,
    webhookClient: null,
    webhookEvent: null,
  },
  ConfigClientPanel: {
    '@type': modulesNames.ConfigClientPanel,
    defaultPaymentMode: 2,
    enableVariantStep: true,
    isOneTimePayPaymentMode: true,
    isSubscriptionPaymentMode: false,
    oneTimePaymentDefaultGateway: 'TPAY',
    orderPaymentDefaultGateway: 'TPAY',
    referralProgramDescription: '',
    hideDisabledDaysOnClientCalendar: false,
    subscriptionPaymentDefaultGateway: 'STRIPE_CARD',
    validateBagChangesWithSlots: false,
    showCalendarIcons: true,
    useAddressesWithLessFields: false,
    allowClientPayWithMoneboxEverywhere: false,
  },

  Fakturownia: {
    '@type': modulesNames.Fakturownia,
    addonsCustomName: '',
    addonsCustomNameEnabled: false,
    allowInvoicesForClient: false,
    bagModificationName: '',
    bagModificationVat: 8,
    deliveryItemName: '',
    department: null,
    dietItemName: '',
    enabled: false,
    host: '',
    integrationType: 'DISABLED',
    token: '',
  },
  PayU: {
    '@type': modulesNames.PayU,
    clientId: null,
    clientSecret: '',
    enabled: false,
    md5Key: '',
    posId: null,
    sandbox: false,
    googlePayMerchantId: null,
  },
  Stripe: {
    '@type': modulesNames.Stripe,
    cardPayments: false,
    enabled: false,
    linkPayments: false,
    publishableKey: '',
    rulesLink: null,
    secretKey: '',
  },
  MyLead: {
    '@type': modulesNames.MyLead,
    clickId: null,
    enabled: false,
    payoutDecimal: null,
    providerHash: null,
    userId: null,
  },
  ClickOnMetrics: {
    '@type': modulesNames.ClickOnMetrics,
    enabled: false,
    trackerId: null,
  },
  PayNow: {
    '@type': modulesNames.PayNow,
    clientId: null,
    clientSecret: '',
    enabled: false,
    md5Key: '',
    posId: null,
    sandbox: false,
  },
  AdditionalPayment: {
    '@type': modulesNames.AdditionalPayment,
    cateromarket: false,
    dietly: false,
    dine2door: false,
    groupon: false,
    transfer: false,
    other: false,
  },
  Foodsi: {
    '@type': modulesNames.Foodsi,
    enabled: false,
  },
};

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const dayFields = [
  'newOrder',
  'newOrderSubscription',
  'changeDate',
  'changeAddress',
  'changeDiet',
  'buyAddons',
  'paymentDeadline',
  'shouldBeDeliveredIn',
  'shouldBeDeliveredInBlock',
];

export const timeFields = [
  'newOrder',
  'newOrderSubscription',
  'buyAddons',
  'changeDiet',
  'changeDate',
  'changeAddress',
  'paymentDeadline',
  'rate',
];

export const dayObject = (curr = {}, fields = dayFields) => {
  let obj = {};

  fields.forEach(field => {
    obj[field] = 0;
  });

  return { ...obj, ...curr };
};
