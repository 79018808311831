import { useTranslation } from 'react-i18next';

import roles from 'helpers/roles';
import getReportFileName from 'helpers/getReportFileName';
import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';

import MIME_TYPES from './constants/mimeTypes';
import BaseBrandReport from './BaseBrandReport';

const SelectionsCodes = () => {
  const { t } = useTranslation();

  const isUsingV4Codes = useIsUsingV4Codes();

  const sharedProps = {
    excelUrl: '/reports/selections',
    mimeType: MIME_TYPES.xlsx,
    role: roles.ROLE_SHOW_REPORT_SELECTIONS,
    excelAvailable: true,
    dateType: 'single',
    isSalesMarketLangViewEnabled: true,
    archiveReportNames: ['SELECTIONS'],
  };

  if (isUsingV4Codes) {
    const title = t('reports.reportsSelectionsV4');

    return (
      <BaseBrandReport
        {...sharedProps}
        title={title}
        fileName={getReportFileName(title)}
      />
    );
  }

  const title = t('reports.reportsSelections');

  return (
    <BaseBrandReport
      {...sharedProps}
      title={title}
      fileName={getReportFileName(title)}
    />
  );
};

export default SelectionsCodes;
