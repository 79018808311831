import React from 'react';
import PropTypes from 'prop-types';
import { login, handleBrandChange, setTemporaryLock } from 'actions/Auth';
import { post } from 'helpers/apiHelpers';
import moment from 'moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import S from './AuthStyled';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { removeHelpScout } from 'utils/helpScout';

import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      errorMessage: '',
      username: '',
      password: '',
      forgotPassword: false,
      passwordSent: false,
      intervalId: null,
      minutesUntilScreenUnlocked: null,
      isLoading: false,
    };
  }

  iconStyles = { color: '#BDBDBD', marginTop: '6px' };

  componentDidMount() {
    removeHelpScout();

    if (this.props.brand !== null) {
      this.props.handleBrandChange(null, {
        refresh: false,
        brand: { id: null },
      });
    }

    if (
      this.props.temporaryAccountLock &&
      moment().isSameOrAfter(moment(this.props.temporaryLockExpiredAt))
    ) {
      this.props.setTemporaryLock(false);
    }

    if (
      this.props.temporaryAccountLock &&
      moment().isBefore(moment(this.props.temporaryLockExpiredAt))
    ) {
      this.setScreenUnlockInterval();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
    clearInterval(this.intervalId);
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = async ev => {
    ev.preventDefault();

    this.setState({ isLoading: true });

    this.state.forgotPassword
      ? post('/lost-password', { email: this.state.username })
          .then(
            () => {
              this.setState({ passwordSent: true });
            },
            error => {
              this.setState({
                errorMessage: this.props.t(
                  'common.loginForm.emailNotFoundMessage'
                ),
              });
            }
          )
          .finally(() => this.setState({ isLoading: false }))
      : this.props
          .login(this.state.username.toLowerCase(), this.state.password)
          .then(
            () => {
              this.setState({
                errorMessage: '',
              });
              if (
                typeof window.requestedUrl !== 'undefined' &&
                window.requestedUrl !== ''
              ) {
                this.props.history.push(window.requestedUrl);
                window.requestedUrl = '';
              } else {
                this.props.history.push('/admin/dashboard');
              }
            },
            async error => {
              if (error.noBrands) {
                return this.setState({
                  errorMessage: (
                    <span>
                      Nie masz dostępu do żadnej marki.
                      <br /> Aby uzyskać odpowiednie uprawnienia, skontaktuj się
                      z administratorem.
                    </span>
                  ),
                });
              } else if (error?.response?.data?.time) {
                await this.props.setTemporaryLock(true);
                this.setScreenUnlockInterval();
              } else {
                return this.setState({
                  errorMessage: this.props.t(
                    'common.loginForm.incorrectLoginDetailsMessage'
                  ),
                });
              }
            }
          )
          .finally(() => this.setState({ isLoading: false }));
  };

  setMinutesUntilScreenUnlocked = () => {
    const timeDifference = moment(this.props.temporaryLockExpiredAt).diff(
      moment(),
      'minutes'
    );
    this.setState({ minutesUntilScreenUnlocked: timeDifference + 1 });
  };

  setScreenUnlockInterval = () => {
    this.setMinutesUntilScreenUnlocked();

    const intervalId = setInterval(() => {
      this.setMinutesUntilScreenUnlocked();

      if (this.state.minutesUntilScreenUnlocked < 1) {
        this.props.setTemporaryLock(false);
      }
    }, 10000);

    this.setState({ intervalId: intervalId });
  };

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        {!this.props.temporaryAccountLock && (
          <S.AuthHeader>
            {this.state.forgotPassword
              ? t('common.loginForm.passwordRemainderCaption')
              : t('common.loginForm.loginCaption')}
          </S.AuthHeader>
        )}

        {this.state.forgotPassword ? (
          <>
            {this.state.passwordSent ? (
              <>
                <S.ResetPasswordInfoWrapper>
                  <S.AuthParagraph>
                    {t('common.loginForm.checkEmail')}
                  </S.AuthParagraph>
                  <S.AuthParagraph>
                    {t('common.loginForm.passwordLinkSent')}
                  </S.AuthParagraph>
                  <S.AuthLink
                    onClick={() =>
                      this.setState({
                        forgotPassword: false,
                        passwordSent: false,
                      })
                    }
                  >
                    {t('common.loginForm.buttonBackToLogin')}
                  </S.AuthLink>
                </S.ResetPasswordInfoWrapper>
              </>
            ) : (
              <>
                <S.AuthParagraph>
                  {t('common.loginForm.typeEmailMessage')}
                </S.AuthParagraph>

                <S.AuthForm onSubmit={this.handleSubmit}>
                  <S.AuthInput>
                    <input
                      type="text"
                      className={`form__input ${
                        this.state.username ? 'label-top' : ''
                      }`}
                      autoComplete="off"
                      name="username"
                      id="email"
                      placeholder=" "
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                    <label className="form__label" htmlFor="email">
                      {t('common.loginForm.emailInputLabel')}
                    </label>
                  </S.AuthInput>

                  {this.state.errorMessage && (
                    <S.ErrorMessage>{this.state.errorMessage}</S.ErrorMessage>
                  )}

                  <S.AuthButton type="submit">
                    {t('common.loginForm.buttonSend')}
                  </S.AuthButton>

                  <S.AuthLink
                    onClick={() =>
                      this.setState({
                        forgotPassword: false,
                        errorMessage: null,
                      })
                    }
                  >
                    {t('common.loginForm.buttonBack')}
                  </S.AuthLink>
                </S.AuthForm>
              </>
            )}
          </>
        ) : (
          <>
            {this.props.temporaryAccountLock ? (
              <S.AccLock>
                <p>{t('common.loginForm.temporaryAccountLockMsg1')}</p>
                <p>{t('common.loginForm.temporaryAccountLockMsg2')}</p>
                <p>
                  {t('common.loginForm.temporaryAccountLockMsg3', {
                    time: this.state.minutesUntilScreenUnlocked,
                  })}
                </p>
              </S.AccLock>
            ) : (
              <>
                {this.state.errorMessage ? (
                  <S.ErrorMessage>
                    {this.state.errorMessage}
                    <span>{t('common.loginForm.tryAgain')}</span>
                  </S.ErrorMessage>
                ) : (
                  <S.AuthParagraph>
                    {t('common.loginForm.loginInfoMsg')}
                  </S.AuthParagraph>
                )}

                <S.AuthForm onSubmit={this.handleSubmit}>
                  <S.AuthInput>
                    <input
                      type="text"
                      className={`form__input ${
                        this.state.username ? 'label-top' : ''
                      }`}
                      autoComplete="off"
                      name="username"
                      id="email"
                      placeholder=" "
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                    <label className="form__label" htmlFor="email">
                      {t('common.loginForm.emailInputLabel')}
                    </label>
                  </S.AuthInput>

                  <S.AuthInput
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      className={`form__input ${
                        this.state.password ? 'label-top' : ''
                      }`}
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder=" "
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <label className="form__label" htmlFor="password">
                      {t('common.loginForm.passwordInputLabel')}
                    </label>
                    <button
                      type="button"
                      className="toggle-password"
                      onClick={() =>
                        this.setState(prev => {
                          return {
                            ...prev,
                            showPassword: !this.state.showPassword,
                          };
                        })
                      }
                    >
                      {this.state.showPassword ? (
                        <VisibilityOutlined style={this.iconStyles} />
                      ) : (
                        <VisibilityOffOutlined style={this.iconStyles} />
                      )}
                    </button>
                  </S.AuthInput>
                  <S.AuthButton type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <S.StyledSpinner viewBox="0 0 50 50" size="40px">
                        <circle
                          className="circle"
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="2"
                        />
                      </S.StyledSpinner>
                    ) : (
                      t('common.loginForm.buttonLogin')
                    )}
                  </S.AuthButton>
                  <S.AuthLink
                    onClick={() =>
                      this.setState({ errorMessage: '', forgotPassword: true })
                    }
                  >
                    {t('common.loginForm.forgotPassword')}
                  </S.AuthLink>
                </S.AuthForm>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  errorMessage: state.Auth.errorMessage,
  brand: state.Auth.selectedBrand,
  temporaryAccountLock: state.Auth.temporaryAccountLock,
  temporaryLockExpiredAt: state.Auth.temporaryLockExpiredAt,
});

const mapDispatchToProps = {
  login,
  handleBrandChange,
  setTemporaryLock,
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(loginPageStyle)(LoginPage))
);
