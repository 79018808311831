import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import React from 'react';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from 'components/CustomButtons/Button';
import { post } from 'helpers/apiHelpers';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  divider: {
    marginBottom: '20px',
  },
});

const Caching = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const successCleared = () => {
    toast.success(t('cache.clear.success'));
  };
  const errorOnClear = () => {
    toast.error(t('cache.clear.error'));
  };

  const clearCache = () => {
    post('/brand/clear-cache')
      .then(({ status }) => {
        status ? successCleared() : errorOnClear();
      })
      .catch(() => {
        errorOnClear();
      });
  };

  return (
    <CardWrapper title={t('cache.title')}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <Divider className={classes.divider} />
            <Button color="info" onClick={clearCache} type="button">
              {t('cache.clear')}
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default Caching;
