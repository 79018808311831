import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { handleBrandChange } from 'actions/Auth';
import DataGrid from 'components/DataGrid/DataGrid';
import AdminTable from 'layouts/AdminTable';

import columnConfig from './columnConfig';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { brands } = useSelector(state => state.Auth.user);

  const hasPermissionToEditBrand = brandId =>
    brands.some(({ id }) => id === brandId);

  return (
    <AdminTable title={t('myCompany.brandList')}>
      <DataGrid
        actions={true}
        remove={true}
        paginationTop={true}
        paginationBottom={false}
        url="/brands"
        reportName="brand"
        columns={columnConfig}
        role="BRAND"
        editAction={({ id }) => {
          dispatch(handleBrandChange(null, { brand: { id } }));
          history.push(`${url}/edit/${id}`);
        }}
        canEditChecker={(_, { id }) => hasPermissionToEditBrand(id)}
        canRemoveChecker={(_, { id }) => hasPermissionToEditBrand(id)}
      />
    </AdminTable>
  );
};

export default List;
