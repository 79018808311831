import React from 'react';
import { compose } from 'recompose';

// API Connection
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { DialogLoader } from '../../components/DialogLoader';
import MealNotes from './MealNotes';
import DishSubpage from './DishSubpage';
import BasicMealForm from './BasicMealForm';
// import DishFlowStatistics from './DishFlowStatistics';
import { combineStyles, isGranted } from '../../helpers/helpers';
import { withTranslation } from 'react-i18next';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import NavTabs from 'components/NavTabs/NavTabs';
import ROLES from 'helpers/roles';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button';

const MealForm = props => {
  const {
    t,
    urlSlug,
    classes,
    metaTitle,
    friendlyName,
    handleSubmit,
    metaDescription,
    dishDescription,
    handleCmsInputChange,
    isLoading,
    initialized,
    isSubmitting,
    isEditing,
    dish,
    selectedBrand,
    saveToLs,
  } = props;

  if (isLoading || !initialized) {
    return (
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading', 'Wczytywanie dania...')}
      />
    );
  }

  return (
    <>
      <DialogLoader
        loaderState={isSubmitting}
        text={t('form.savingChanges', 'Zapisywanie zmian...')}
      />
      <NavTabs
        title={isEditing ? t('form.editMeal') : t('form.createMeal')}
        tabs={[
          {
            tabButton: t('common.regular', 'Regular'),
            tabContent: <BasicMealForm {...props} dishId={dish.id} />,
          },

          ...(isGranted(ROLES.ROLE_NOTE_DISH) && isEditing
            ? [
                {
                  tabButton: t('common.notes', 'Notatki'),
                  tabContent: <MealNotes dishId={dish.id} />,
                },
              ]
            : []),

          {
            tabButton: t('$*mealFormComponent.dishSubpage', 'Podstrona dania'),
            tabContent: (
              <DishSubpage
                classes={classes}
                urlSlug={urlSlug}
                metaTitle={metaTitle}
                metaDescription={metaDescription}
                friendlyName={friendlyName}
                dishDescription={dishDescription}
                handleSubmit={handleSubmit}
                handleCmsInputChange={handleCmsInputChange}
              />
            ),
          },

          // ...(isEditing
          //   ? [
          //       {
          //         tabButton: t(
          //           '$*mealFormComponent.flowStats',
          //           'Statystyki flow'
          //         ),
          //         tabContent: (
          //           <DishFlowStatistics
          //             dishId={dish.id}
          //             urlSlug={urlSlug}
          //             metaTitle={metaTitle}
          //             metaDescription={metaDescription}
          //             friendlyName={friendlyName}
          //             dishDescription={dishDescription}
          //             handleCmsInputChange={handleCmsInputChange}
          //           />
          //         ),
          //       },
          //     ]
          //   : []),
        ]}
      >
        {isEditing && (
          <Link
            to={`/admin/meals/add/?savedMeal&selectedBrand=${selectedBrand}`}
            target="_blank"
            className={classes.copyLink}
          >
            <Button
              color="primary"
              variant="contained"
              size="md"
              className={classes.button}
              onClick={saveToLs}
            >
              {t('form.meals.copyToNewMeal', 'Skopiuj do nowego dania')}
            </Button>
          </Link>
        )}
      </NavTabs>
    </>
  );
};

const styles = {
  copyLink: { marginBottom: '12px', display: 'block' },
  button: { margin: 'unset' },
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle, styles);

export default compose(withTranslation(), withStyles(combinedStyles))(MealForm);
