import React from 'react';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Divider } from '@material-ui/core';

import CardWrapper from 'components/Card/CardWrapper';

const OmnibusSettings = ({ classes, modules, setModules, t }) => {
  return (
    <CardWrapper
      title={t('brands.newBrandForm.omnibus', '$$Historia cen - omnibus')}
    >
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <Divider style={{ marginBottom: '20px' }} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    modules?.ConfigClientPanel?.omnibusEnabledForDefaultPrices
                  }
                  onChange={() => {
                    setModules(prevState => ({
                      ...prevState,
                      ConfigClientPanel: {
                        ...prevState.ConfigClientPanel,
                        omnibusEnabledForDefaultPrices:
                          !modules?.ConfigClientPanel
                            ?.omnibusEnabledForDefaultPrices,
                      },
                    }));
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('brands.newBrandForm.omnibus.checkbox')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default OmnibusSettings;
