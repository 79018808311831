import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const selectItem = [
  {
    value: 'PAYU_CARD',
    translationName: 'paymentType.payUCard',
  },
  {
    value: 'PAYU_PAYPO',
    translationName: 'paymentType.payUPayPo',
  },
  {
    value: 'PAYU',
    translationName: 'paymentType.payU',
  },
  {
    value: 'PAYU_BLIK',
    translationName: 'paymentType.payUBlik',
  },
  {
    value: 'STRIPE_CARD',
    translationName: 'paymentType.stripeCard',
  },
  {
    value: 'STRIPE_LINK',
    translationName: 'paymentType.stripeLink',
  },
  {
    value: 'PAYNOW',
    translationName: 'paymentType.payNow',
  },
];

const StandardPaymentTypesSelectItems = ({
  classes,
  paymentTypes,
  validateSetDefaultPaymentTypes,
  setPaymentTypes,
}) => {
  const { t } = useTranslation();

  const MenuItemClasses = {
    root: classes.selectMenuItem,
    selected: classes.selectMenuItemSelectedMultiple,
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <Select
        value={paymentTypes?.standard?.defaultPaymentType}
        onChange={e => {
          !validateSetDefaultPaymentTypes(e, 'standard') &&
            setPaymentTypes(prev => ({
              ...prev,
              standard: {
                ...prev.standard,
                defaultPaymentType: e.target.value,
              },
            }));
        }}
        displayEmpty
        name="dietStandard"
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
      >
        {selectItem.map(item => (
          <MenuItem
            key={item.value}
            value={item.value}
            classes={MenuItemClasses}
          >
            {t(item.translationName)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StandardPaymentTypesSelectItems;
