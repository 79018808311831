import { Router, Route, Switch } from 'react-router';
import { useRouteMatch, useHistory } from 'react-router-dom';

import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_BRAND,
  ROLE_EDIT_BRAND,
  ROLE_SHOW_BRAND,
} from 'helpers/roles';
import BrandSettings from 'views/BrandConfiguration/BrandSettings';

import List from './List';

const Brands = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const EditForm = withRole(ROLE_EDIT_BRAND, BrandSettings);
  const CreateForm = withRole(ROLE_CREATE_BRAND, BrandSettings);
  const BrandList = withRole(ROLE_SHOW_BRAND, List);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={url} component={BrandList} />
        <Route
          path={`${url}/add-new-brand`}
          render={() => <CreateForm isEdit={false} />}
        />
        <Route
          path={`${url}/edit/:id`}
          render={() => <EditForm isEdit={true} />}
        />
      </Switch>
    </Router>
  );
};

export default Brands;
