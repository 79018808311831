import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { refreshLogin } from 'actions/Auth';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import userPhotoTemplate from 'assets/img/new_logo.png';
import { put } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';
import Muted from 'components/Typography/Muted';
import SelectAll, { ALL_SELECTED_VALUE } from 'components/SelectAll';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import PasswordInput from 'components/PasswordInput/PasswordInput';

const useExternalStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
});

const useStyles = makeStyles({
  form: {
    marginTop: '15px',
  },
  imageLabel: {
    marginBottom: '20px',
  },
  statisticsForBrands: {
    marginTop: '16px',
  },
  statisticsForBrandsLabel: {
    alignItems: 'center',
  },
});

const MyProfile = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const externalClasses = useExternalStyles();
  const classes = useStyles();

  const { user } = useSelector(state => state.Auth);

  const isStatisticsForBrandsActive =
    isGranted(roles.ROLE_SHOW_STATISTICS_DIET) && user.brands.length > 0;

  const [formValues, setFormValues] = useState({
    email: user.email.toLowerCase() || '',
    firstName: user.firstName || '',
    image: user.image || null,
    imageUrl: user.imageUrl || null,
    lastName: user.lastName || '',
    phone: {
      countryCode: user.phoneCountryCode || '',
      number: user.phone || '',
    },
    plainPassword: '',
    plainPasswordMatch: '',
    statisticsForBrands:
      user.statisticsForBrands?.map(brand => brand['@id']) || [],
    showPassword: false,
    showPasswordRepeat: false,
  });

  const handleInputChange = event => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFormValueChange = (name, value) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formValues.email.includes('@')) {
      toast.error(t('myCompany.toast.incorrectEmail'));

      return false;
    }

    if (formValues.plainPasswordMatch !== formValues.plainPassword) {
      toast.error(t('errors.passwordsDontMatch'));

      return false;
    }

    if (!formValues.firstName || !formValues.lastName || !formValues.phone) {
      toast.error(t('errors.fillAllRequiredFields'));

      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await put(user.id, {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phone: formValues.phone,
        email: formValues.email,
        image: formValues.image,
        plainPassword:
          formValues.plainPassword !== '' ? formValues.plainPassword : null,
        ...(isStatisticsForBrandsActive && {
          statisticsForBrands: formValues.statisticsForBrands.filter(
            brand => brand !== ALL_SELECTED_VALUE
          ),
        }),
      });

      toast.success(t('success.dataUpdated'));

      dispatch(refreshLogin());
    } catch {}
  };

  const statisticsForBrandsSelectOptions = user.brands.map(brand => ({
    value: brand['@id'],
    label: brand.name,
  }));

  return (
    <form className={classes.form}>
      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('user.profile')}>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <FormTextInput
                    label={`${t('user.firstName')} *`}
                    classes={externalClasses}
                    name="firstName"
                    value={formValues.firstName}
                    handleChange={handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={`${t('user.lastName')} *`}
                    classes={externalClasses}
                    name="lastName"
                    value={formValues.lastName}
                    handleChange={handleInputChange}
                    inputSize={12}
                  />
                  <ReactPhoneNumberAdapter
                    label={`${t('user.phone')} *`}
                    name="phone"
                    value={formValues.phone}
                    onChange={(number, countryCode) => {
                      setFormValues(prevFormValues => ({
                        ...prevFormValues,
                        phone: {
                          countryCode,
                          number,
                        },
                      }));
                    }}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={`${t('user.email')} *`}
                    classes={externalClasses}
                    name="email"
                    value={formValues.email}
                    handleChange={handleInputChange}
                    inputSize={12}
                  />
                  <PasswordInput
                    label={t('employees.newPass')}
                    name="plainPassword"
                    value={formValues.plainPassword}
                    state={formValues}
                    setState={setFormValues}
                    showPasswordKey="showPassword"
                    autoComplete="new-password"
                  />

                  <PasswordInput
                    label={t('employees.rNewpass')}
                    name="plainPasswordMatch"
                    value={formValues.plainPasswordMatch}
                    state={formValues}
                    setState={setFormValues}
                    showPasswordKey="showPasswordRepeat"
                    focusKey="focusedPasswordRepeat"
                    repeatInput
                    autoComplete="off"
                  />

                  <Muted>{t('user.passwordHint')}</Muted>
                </GridItem>
                <GridItem md={6}>
                  <FormLabel
                    className={classNames(
                      externalClasses.labelHorizontal,
                      classes.imageLabel
                    )}
                  >
                    {t('user.image')}
                  </FormLabel>
                  <FormImageUpload
                    classes={externalClasses}
                    stateName="image"
                    getImage={(stateName, data) => {
                      setFormValues(prevFormValues => ({
                        ...prevFormValues,
                        image: data?.['@id'] || null,
                        imageUrl: data?.contentUrl || null,
                      }));
                    }}
                    removeImage={() => {
                      setFormValues(prevFormValues => ({
                        ...prevFormValues,
                        image: null,
                        imageUrl: null,
                      }));
                    }}
                    previewUrl={formValues.imageUrl}
                    defaultImage={userPhotoTemplate}
                  />
                </GridItem>

                {isStatisticsForBrandsActive && (
                  <GridItem className={classes.statisticsForBrands} md={6}>
                    <FormLabel
                      className={classNames(
                        externalClasses.labelHorizontal,
                        classes.statisticsForBrandsLabel
                      )}
                    >
                      {t('user.getBrandsNotifications')}{' '}
                      <InfoIconTooltip
                        title={t('user.getBrandsNotificationsHint')}
                      />
                    </FormLabel>
                    <SelectAll
                      options={statisticsForBrandsSelectOptions}
                      trackBy="value"
                      mapBy="label"
                      optionSelected={formValues.statisticsForBrands.map(
                        value => ({
                          value,
                          label:
                            value === ALL_SELECTED_VALUE
                              ? undefined
                              : statisticsForBrandsSelectOptions.find(
                                  selectOption => selectOption.value === value
                                ).label,
                        })
                      )}
                      handleChange={selected => {
                        handleFormValueChange(
                          'statisticsForBrands',
                          selected.map(({ value }) => value)
                        );
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
              <FormControlButtons
                classes={externalClasses}
                submitText={t('user.save')}
                handleSubmit={handleSubmit}
              />
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default MyProfile;
