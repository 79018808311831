import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import EXTERNAL_URLS from 'constants/externalUrls';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';

const StripeModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'StripeModule',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <h3
            style={{
              display: 'flex',
              gap: '4px',
              marginLeft: '15px',
            }}
          >
            {t('paymentType.title.Stripe', 'Ustawienia Stripe')}
            <InfoWithTooltip
              link={EXTERNAL_URLS.configurationStripe}
              linkText={t('paymentType.configurationStripe.tooltip')}
            />
          </h3>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <FormTextInput
          label="Publishable Key"
          classes={classes}
          value={module.publishableKey}
          handleChange={e =>
            handleModuleUpdate({ ...module, publishableKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Secret Key"
          classes={classes}
          value={module.secretKey}
          handleChange={e =>
            handleModuleUpdate({ ...module, secretKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Webhook Signing Secret"
          classes={classes}
          value={module.webhookSecret}
          handleChange={e =>
            handleModuleUpdate({ ...module, webhookSecret: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'config.modules.Stripe.subscriptionConditionsAddress',
            'Adres do regulaminu płatności cyklicznych'
          )}
          classes={classes}
          value={module.rulesLink}
          handleChange={e =>
            handleModuleUpdate({
              ...module,
              rulesLink: e.target.value,
            })
          }
          inputSize={12}
          maxLength={255}
        />
      </GridItem>
    </Fragment>
  );
};

export default StripeModule;
