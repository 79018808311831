import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  closeButton: {
    marginLeft: 'auto',
    minWidth: 'unset',
  },
  dialogTitle: {
    padding: '4px',
    '& h2': { display: 'flex' },
  },
  dialogHeading: {
    fontSize: '1.25rem',
    marginTop: '0',
    marginBottom: '42px',
  },
  dialogContent: {
    padding: '0 48px 38px 48px',
  },
  paragraph: {
    fontSize: '1rem',
    '&:not(:last-child)': {
      marginBottom: '42px',
    },
  },
});

const CodingVersionWarningModal = ({
  isOpen,
  onClose,
  mealTypesMissingBrands,
  sizesMissingBrands,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.dialogTitle}>
        <Button className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <h2 className={classes.dialogHeading}>
          {t('myCompany.mealsCodingVersion.warningModal.title')}
        </h2>
        {mealTypesMissingBrands.length > 0 && (
          <p className={classes.paragraph}>
            <Trans
              i18nKey="myCompany.mealsCodingVersion.warningModal.mealTypesMissingBrands"
              components={{ b: <b /> }}
              values={{ brands: mealTypesMissingBrands.join(', ') }}
            />
          </p>
        )}
        {sizesMissingBrands.length > 0 && (
          <p className={classes.paragraph}>
            <Trans
              i18nKey="myCompany.mealsCodingVersion.warningModal.sizesMissingBrands"
              components={{ b: <b /> }}
              values={{ brands: sizesMissingBrands.join(', ') }}
            />
          </p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CodingVersionWarningModal;
