import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const withErrorHandler = WrappedComponent => {
  class WithErrorHandler extends Component {
    state = {
      hasErrors: false,
      errors: {},
    };

    errorHandlerResetState = () => {
      this.setState({
        hasErrors: false,
        errors: {},
      });
    };

    errorHandlerCatch = error => {
      const {
        data: { violations = [] },
        status,
      } = error.response;

      const { t } = this.props;
      if (status === 500) {
        return toast.error(
          `${t('errors.serverNotResponding')} ${t('errors.tryAgainLater')}`
        );
      }

      const tempErrors =
        violations.reduce((acc, { message, propertyPath }) => {
          return { ...acc, [propertyPath]: message };
        }, {}) || {};

      this.setState({
        hasErrors: true,
        errors: tempErrors,
      });
    };

    render() {
      const { hasErrors, errors } = this.state;

      return (
        <WrappedComponent
          hasErrors={hasErrors}
          errors={errors}
          errorHandlerResetState={this.errorHandlerResetState}
          errorHandlerCatch={this.errorHandlerCatch}
          {...this.props}
        />
      );
    }
  }

  WithErrorHandler.displayName = `WithErrorHandler(${WrappedComponent.name})`;

  return withTranslation()(WithErrorHandler);
};

export default withErrorHandler;
