import AdminTable from 'layouts/AdminTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import LogView from 'components/History/LogView';
import CheckSizes from '../CheckSizes';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { Info } from '@material-ui/icons';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { makeStyles } from '@material-ui/styles';
import useIsUsingV4Codes from 'hooks/redux/Companies/useIsUsingV4Codes';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  tooltip: { marginLeft: '5px' },
});

const FormView = ({
  state,
  setState,
  typeId,
  isEdit,
  handleChangePrefixV4,
  handleInputChange,
  handleToggle,
  handleCalories,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isUsingV4Codes = useIsUsingV4Codes();

  return (
    <>
      <AdminTable
        title={isEdit ? t('form.editMealType') : t('form.addNewMealType')}
      >
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('form.field.typeName') + '*'}
              classes={classes}
              name="name"
              value={state.name}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={3}>
            <FormTextInput
              label={t('form.field.shortName') + '*'}
              classes={classes}
              name="shortName"
              value={state.shortName}
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={2} style={{ marginTop: '8px' }}>
            <FormTextInput
              label={t('form.field.order') + '*'}
              classes={classes}
              name="position"
              value={state.position}
              type="number"
              handleChange={handleInputChange}
              inputSize={12}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('form.field.code') + '*'}
              classes={classes}
              name="code"
              value={state.code}
              handleChange={handleInputChange}
              errors={state.errors}
            />
          </GridItem>
          <GridItem sm={3}>
            <FormTextInput
              label={
                <>
                  {t('form.field.mealTypesPrefixV4')}
                  {isUsingV4Codes && '*'}
                  <Tooltip
                    className={classes.tooltip}
                    title={
                      <h4>
                        <Trans
                          i18nKey="form.fields.mealTypes.prefix_v4"
                          components={{
                            li: <li />,
                            ul: <ul />,
                            p: <p />,
                          }}
                        />
                      </h4>
                    }
                    placement="right"
                  >
                    <Info fontSize="small" />
                  </Tooltip>
                </>
              }
              maxLength={5}
              classes={classes}
              name="codeV4"
              value={state.codeV4}
              handleChange={handleChangePrefixV4}
              inputSize={12}
            />
          </GridItem>
          <GridItem sm={4}>
            <FormSelectSingle
              classes={classes}
              label={t('common.mainMenu.mealCategory')}
              options={state.mealCategoryOptions}
              value={state.mealCategory}
              mapBy={'value'}
              handleChange={({ target: { value } }) =>
                setState(prevState => ({
                  ...prevState,
                  mealCategory:
                    (value?.['@id'] ?? value) ===
                    (prevState.mealCategory?.['@id'] ?? prevState.mealCategory)
                      ? null
                      : value,
                }))
              }
              name={'mealCategories'}
              id={'mealCategories'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={4}>
            <FormSelectSingle
              classes={classes}
              label={t('common.mainMenu.mealType.googleFit')}
              options={[
                {
                  value: t('common.mainMenu.mealType.googleFit.notset'),
                  key: 'NOTSET',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.unknown'),
                  key: 'UNKNOWN',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.breakfast'),
                  key: 'BREAKFAST',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.Lunch'),
                  key: 'LUNCH',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.snack'),
                  key: 'SNACK',
                },
                {
                  value: t('common.mainMenu.mealType.googleFit.Dinner'),
                  key: 'DINNER',
                },
              ]}
              value={state?.mealTypesGoogleFit}
              mapBy="value"
              trackBy="key"
              handleChange={(e, obj) =>
                setState(prevState => ({
                  ...prevState,
                  mealTypesGoogleFit: obj,
                }))
              }
              name={'mealTypesGoogleFit'}
              id={'mealTypesGoogleFit'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
          </GridItem>
        </GridContainer>
        <CheckSizes
          classes={classes}
          handleToggle={handleToggle}
          handleInputChange={handleInputChange}
          handleCalories={handleCalories}
          sizes={state.sizes}
        />
      </AdminTable>
      {!isEdit && (
        <FormControlStickyButton
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/types"
          handleSubmit={handleSubmit}
        />
      )}
      {isEdit && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/types"
          handleSubmit={handleSubmit}
          iri={`/meal-types/${typeId}`}
        />
      )}
    </>
  );
};

export default FormView;
