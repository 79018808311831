import PayUInfoWithTooltip from 'views/BrandConfiguration/PayUInfoWithTooltip';
import PaymentRow from './PaymentRow';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';

const PaymentMethodManagementSection = ({
  classes,
  modules,
  paymentTypes,
  setModules,
  validateChangeStatusPaymentType,
  handleChangePaymentMethod,
}) => {
  const { t } = useTranslation();

  const paymentRowSharedProps = {
    modules,
    paymentTypes,
    setModules,
    validateChangeStatusPaymentType,
    handleChangePaymentMethod,
  };

  return (
    <>
      <TableRow>
        <TableCell rowSpan={6}>
          <div className={classes.payUTitle}>
            PayU
            <PayUInfoWithTooltip />
          </div>
        </TableCell>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_CARD',
            paymentTypeSubscription: 'PAYU_CARD',
            option: 'cardPaymentsEnabled',
            method: t('paymentType.payUCardNew'),
            tooltip: (
              <Tooltip
                title={<h4>{t('paymentType.payUCard.tooltip')}</h4>}
                placement="right"
              >
                <Info fontSize="small" />
              </Tooltip>
            ),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_PAYPO',
            option: 'payPo',
            method: t('paymentType.payUPayPoNew'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_GOOGLE_PAY',
            option: 'googlePayEnabled',
            method: t('paymentType.payUGooglePayNew'),
            tooltip: (
              <InfoWithTooltip
                link={EXTERNAL_URLS.configurationGooglePay}
                linkText={t('paymentType.configurationGooglePay.tooltip')}
              />
            ),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU',
            method: t('paymentType.payU'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_BLIK',
            option: 'blik',
            method: t('paymentType.payUBlikNew'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_SANDBOX',
            option: 'sandbox',
            method: t('paymentType.payUSandbox'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2}>
          <div className={classes.payUTitle}>
            Stripe
            <InfoWithTooltip
              link={EXTERNAL_URLS.configurationStripe}
              linkText={t('paymentType.configurationStripe.tooltip')}
            />
          </div>
        </TableCell>
        <PaymentRow
          value={{
            title: 'Stripe',
            paymentTypeStandard: 'STRIPE_CARD',
            paymentTypeSubscription: 'STRIPE_CARD',
            option: 'cardPayments',
            method: t('paymentType.stripeCardNew'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'Stripe',
            paymentTypeStandard: 'STRIPE_LINK',
            method: t('paymentType.stripeLink'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell>PayPo</TableCell>
        <PaymentRow
          value={{
            title: 'PayPo',
            paymentTypeStandard: 'PAYPO',
            method: t('paymentType.payPo'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2}>
          <div className={classes.payUTitle}>
            PayNow
            <InfoWithTooltip
              link={EXTERNAL_URLS.configurationPayNow}
              linkText={t('paymentType.configurationPayNow.tooltip')}
            />
          </div>
        </TableCell>
        <PaymentRow
          value={{
            title: 'PayNow',
            paymentTypeStandard: 'PAYNOW',
            method: t('paymentType.payNow'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayNow',
            paymentTypeStandard: 'PAYNOW_SANDBOX',
            option: 'sandbox',
            method: t('paymentType.payNowSandbox'),
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
    </>
  );
};

export default PaymentMethodManagementSection;
