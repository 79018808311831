import { useEffect, useState } from 'react';
import { get, put } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';
import { ROLE_SHOW_NOTE, ROLE_NOTE_CLIENT } from 'helpers/roles';
import Moment from 'moment';

import { fetchUser } from 'actions/Users';
import { fetchDiets } from 'actions/Diets';
import { fetchMealTypes } from 'actions/MealTypes';
import { fetchVariants } from 'actions/Variants';

import { connect } from 'react-redux';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';

import 'views/Orders/calendar.css';
import DietDetailsNotes from './DietDetailsNotes';
import DietDetailsHeader from './DietDetailsHeader';
import DietDetailsClientData from './DietDetailsClientData';
import DietDetailsMoneyBox from './DietDetailsMoneybox';
import DietDetailsAddress from './DietDetailsAddress';
import SingleDayDetails from './SingleDayDetails';

import Calendar from './Calendar';
import DietDetailsPrices from './DietDetailsPrices';
import { DialogLoader } from 'components/DialogLoader';
import LogView from 'components/History/LogView';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import CreatedBy from './CreatedBy';
import dates from 'react-big-calendar/lib/utils/dates';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  mainCard: { minWidth: '1280px', marginBottom: '0px' },
  mainCardWrapper: { overflowX: 'scroll', marginBottom: '30px' },
  newOrderWrapper: { marginLeft: '5px', marginBottom: '10px' },
});

const localizer = BigCalendar.momentLocalizer(moment);

const DietDetails = ({
  diets,
  match,
  history,
  variants,
  fetchUser,
  fetchDiets,
  fetchVariants,
  mealTypes,
  fetchMealTypes,
}) => {
  const [pickupPoints, setPickupPoints] = useState([]);
  const [addressType, setAddressType] = useState('');
  const [bagInfo, setBagInfo] = useState(null);
  const [selectedMealTypes, setSelectedMealTypes] = useState([]);
  const [brandInfo, setBrandInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [isCreatedByEmployee, setIsCreatedByEmployee] = useState(false);

  const [selectedDiet, selectDiet] = useState('');

  const [client, setClient] = useState({});
  const [dietInfo, setDietInfo] = useState(null);
  const [allDiets, setAllDiets] = useState([]);
  const [clientAddresses, setClientAddresses] = useState([]);
  const [notes, setNotes] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [fetchingDay, setFetchingDay] = useState(false);
  const [firstVisibleDay, setFirstVisibleDay] = useState('');
  const [lastVisibleDay, setLastVisibleDay] = useState('');
  const [isSelectedDaySubscriptionIntent, setIsSelectedDaySubscription] =
    useState(false);

  const [historyOrder, setHistoryOrder] = useState({});

  const [calendarNeedsUpdate, setCalendarNeedsUpdate] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchOnDietChange = ecommerceDietId => {
    Promise.all([
      //fetch all info about diet
      get(`/ecommerce-diets/${ecommerceDietId}`),
    ])
      .then(([ecommerceDiet]) => {
        const clientId = ecommerceDiet.client['@id'];

        const firstDeliveryDate = new moment(
          dates.firstVisibleDay(
            new Date(ecommerceDiet.firstDeliveryDate),
            localizer
          )
        ).format('YYYY-MM-DD');

        const lastDeliveryDate = new moment(
          dates.lastVisibleDay(
            new Date(ecommerceDiet.lastDeliveryDate),
            localizer
          )
        ).format('YYYY-MM-DD');

        let pureClientId = clientId.match(/\d/g);
        pureClientId = pureClientId.join('');

        setDietInfo(ecommerceDiet);
        setFirstVisibleDay(firstDeliveryDate);
        setLastVisibleDay(lastDeliveryDate);

        return Promise.all([
          //get client addresses
          get(`${ecommerceDiet.client['@id']}/addresses`),
          //get client
          get(clientId),
          //get client notes
          get(`/notes`, { key: clientId }),
          // get all client diets
          get('ecommerce-diets', {
            pagination: false,
            'client.id': pureClientId,
            'properties[id]': 'value',
            'properties[name]': 'value',
            'properties[active]': 'value',
          }),
          // get order
          get(ecommerceDiet.order['@id']),
          // get order history
          get(ecommerceDiet.order['@id'], {
            showHistory: true,
            actionType: 'INSERT',
            'order.id': 'ASC',
          }),
        ]);
      })
      .then(([addresses, client, notes, allDiets, orderInfo, history]) => {
        //after get
        setCalendarNeedsUpdate(true);
        setNotes(notes['hydra:member']);
        setClient(client);
        setClientAddresses(addresses['hydra:member']);
        setOrderInfo(orderInfo);
        setAllDiets(
          allDiets['hydra:member'].map(diet => {
            const dietId = diet.id;
            const dietName = diet?.name ? `- ${diet?.name}` : '';
            return {
              label: diet.active ? (
                <b>
                  <Trans i18nKey="dietDetails.activeDietId" dietId={dietId}>
                    ID #{{ dietId }} {t('diets.active')}
                  </Trans>{' '}
                  {dietName}
                </b>
              ) : (
                <span>
                  ID #{diet.id} {t('diets.inactive')} {dietName}
                </span>
              ),
              active: diet.active,
              id: diet.id,
              value: diet['@id'],
            };
          })
        );

        const historyCreateOrder = history['hydra:member'][0];

        setIsCreatedByEmployee(
          historyCreateOrder?.impersonator
            ? historyCreateOrder?.impersonator?.['@type'] === 'Employee'
            : historyCreateOrder?.actor?.['@type'] === 'Employee'
        );
        setHistoryOrder(historyCreateOrder);
        setLoading(false);
      });
  };

  const fetchData = dietId => {
    Promise.all([
      //fetch all info about diet
      get(`/ecommerce-diets/${match.params.id}`),
      // get history
      get('/pick-up-points'),
      fetchDiets(),
      fetchVariants(),
      fetchMealTypes(),
    ])
      .then(([ecommerceDiet, pickupPoints]) => {
        const clientId = ecommerceDiet.client['@id'];
        let pureClientId = clientId.match(/\d/g);
        pureClientId = pureClientId.join('');

        const firstDeliveryDate = new moment(
          dates.firstVisibleDay(
            new Date(ecommerceDiet.firstDeliveryDate),
            localizer
          )
        ).format('YYYY-MM-DD');

        const lastDeliveryDate = new moment(
          dates.lastVisibleDay(
            new Date(ecommerceDiet.lastDeliveryDate),
            localizer
          )
        ).format('YYYY-MM-DD');

        setPickupPoints(pickupPoints['hydra:member']);
        setDietInfo(ecommerceDiet);
        setFirstVisibleDay(firstDeliveryDate);
        setLastVisibleDay(lastDeliveryDate);

        return Promise.all([
          //get client addresses
          get(`${ecommerceDiet.client['@id']}/addresses`),
          //get client
          get(clientId),
          //get client notes
          get(`/notes`, { key: clientId }),
          // get all client diets
          get('ecommerce-diets', {
            pagination: false,
            'client.id': pureClientId,
            'properties[id]': 'value',
            'properties[name]': 'value',
            'properties[active]': 'value',
          }),
          // get order
          get(ecommerceDiet.order['@id']),
          // get order history
          get(ecommerceDiet.order['@id'], {
            showHistory: true,
            actionType: 'INSERT',
            'order[id]': 'ASC',
          }),
        ]);
      })
      .then(([addresses, client, notes, allDiets, orderInfo, history]) => {
        //after get
        setNotes(notes['hydra:member']);
        setClient(client);
        setClientAddresses(addresses['hydra:member']);

        setOrderInfo(orderInfo);
        setAllDiets(
          allDiets['hydra:member'].map(diet => {
            const dietId = diet.id;
            const dietName = diet?.name ? `- ${diet?.name}` : '';

            return {
              label: diet.active ? (
                <b>
                  <Trans i18nKey="dietDetails.activeDietId" dietId={dietId}>
                    ID #{{ dietId }} {t('diets.active')}
                  </Trans>{' '}
                  {dietName}
                </b>
              ) : (
                <span>
                  ID #{diet.id} {t('diets.inactive')} {dietName}
                </span>
              ),
              active: diet.active,
              id: diet.id,
              value: diet['@id'],
            };
          })
        );
        const historyCreateOrder = history['hydra:member'][0];
        setIsCreatedByEmployee(
          historyCreateOrder?.impersonator
            ? historyCreateOrder?.impersonator?.['@type'] === 'Employee'
            : historyCreateOrder?.actor?.['@type'] === 'Employee'
        );

        setHistoryOrder(historyCreateOrder);
        setLoading(false);
      });
  };

  if (isLoading) {
    return <DialogLoader loaderState={isLoading} text={'Ładowanie...'} />;
  }

  const newOrder = () => {
    history.push(`/admin/orders/add?userId=${client.id}`);
  };

  const createdDate =
    historyOrder &&
    new Moment(historyOrder.loggedAt).format(
      Moment.localeData().longDateFormat('L')
    );
  const createdTime =
    historyOrder && new Moment(historyOrder.loggedAt).format('HH:mm:ss');

  const removeAddons = addonId => {
    const dietSubscriptionId = dietInfo?.subscription?.id;

    const url = isSelectedDaySubscriptionIntent
      ? `subscription/${dietSubscriptionId}/remove-addons`
      : `${bagInfo['@id']}/remove-addons`;

    put(url, {
      addons: [addonId],
    }).then(setBagInfo);
  };

  return (
    <div>
      <CreatedBy
        isCreatedByEmployee={isCreatedByEmployee}
        dietCreator={historyOrder}
        createdDate={createdDate}
        createdTime={createdTime}
      />
      <div className={classes.mainCardWrapper}>
        <Card className={classes.mainCard}>
          <CardHeader>
            <GridContainer>
              <GridItem md={12}>
                <DietDetailsHeader
                  classes={classes}
                  fetchOnDietChange={fetchOnDietChange}
                  allDiets={allDiets}
                  orderInfo={orderInfo}
                  dietInfo={dietInfo}
                  history={history}
                  client={client}
                />
              </GridItem>
            </GridContainer>
            <Divider />
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem md={2}>
                <div className={classes.newOrderWrapper}>
                  <Button
                    variant="outlined"
                    fullWidth={true}
                    onClick={() => newOrder()}
                  >
                    {t('orders.newOrder')} +
                  </Button>
                </div>
                <DietDetailsPrices dietInfo={dietInfo} />
                <DietDetailsClientData classes={classes} client={client} />
                <DietDetailsAddress
                  setClientAddresses={setClientAddresses}
                  clientAddresses={clientAddresses}
                  client={client}
                  dietInfo={dietInfo}
                />
                <DietDetailsMoneyBox
                  client={client}
                  setClient={setClient}
                  fetchUser={fetchUser}
                />
                {(isGranted(ROLE_SHOW_NOTE) || isGranted(ROLE_NOTE_CLIENT)) && (
                  <DietDetailsNotes
                    classes={classes}
                    setNotes={setNotes}
                    notes={notes}
                    client={client}
                  />
                )}
              </GridItem>
              <GridItem md={7}>
                <Calendar
                  localizer={localizer}
                  match={match}
                  dietInfo={dietInfo}
                  bagInfo={bagInfo}
                  setBagInfo={setBagInfo}
                  setAddressType={setAddressType}
                  setFetchingDay={setFetchingDay}
                  fetchingDay={fetchingDay}
                  fetchOnDietChange={fetchOnDietChange}
                  diets={diets}
                  selectDiet={selectDiet}
                  firstVisibleDay={firstVisibleDay}
                  setFirstVisibleDay={setFirstVisibleDay}
                  lastVisibleDay={lastVisibleDay}
                  setLastVisibleDay={setLastVisibleDay}
                  calendarNeedsUpdate={calendarNeedsUpdate}
                  setCalendarNeedsUpdate={setCalendarNeedsUpdate}
                  setBrandInfo={setBrandInfo}
                  setIsSelectedDaySubscription={setIsSelectedDaySubscription}
                />
              </GridItem>
              <GridItem md={3}>
                <SingleDayDetails
                  classes={classes}
                  match={match}
                  bagInfo={bagInfo}
                  dietInfo={dietInfo}
                  setBagInfo={setBagInfo}
                  variants={variants}
                  diets={diets}
                  mealTypes={mealTypes}
                  fetchOnDietChange={fetchOnDietChange}
                  setAddressType={setAddressType}
                  addressType={addressType}
                  clientAddresses={clientAddresses}
                  pickupPoints={pickupPoints}
                  fetchingDay={fetchingDay}
                  setFetchingDay={setFetchingDay}
                  selectedDiet={selectedDiet}
                  selectDiet={selectDiet}
                  calendarNeedsUpdate={calendarNeedsUpdate}
                  setCalendarNeedsUpdate={setCalendarNeedsUpdate}
                  brandInfo={brandInfo}
                  orderInfo={orderInfo}
                  removeAddons={removeAddons}
                  isSelectedDaySubscriptionIntent={
                    isSelectedDaySubscriptionIntent
                  }
                  selectedMealTypes={selectedMealTypes}
                  setSelectedMealTypes={setSelectedMealTypes}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
      <LogView iri={`/ecommerce-diets/${match.params.id}`} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    diets: state.Diets.diets,
    variants: state.Variants.variants,
    mealTypes: state.MealTypes.mealTypes,
  };
};

const mapDispatchToProps = {
  fetchUser,
  fetchDiets,
  fetchVariants,
  fetchMealTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DietDetails);
