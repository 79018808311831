export const adminMailingOrder = [
  'assign_zone_to_addresses_started',
  'assign_zone_to_addresses_finished',
  'import_diet_prices_started',
  'import_diet_prices_finished',
  'diet_reminder',
  'system_created_new_dish_sizes',
  'employee_welcome',
  'lost_password_admin',
  'daily_statistics',
];

export const clientMailingOrder = [
  'new_account',
  'remove_account',
  'lost_password',
  'client_marketing_terms',
  'new_order',
  'new_order_v2',
  'order_verified',
  'waiting_order_status_change_paid',
  'waiting_order_status_change_canceled',
  'paid_order_status_change_canceled',
  'subscription_renewed_order_successful',
  'subscription_renewed_order_failed',
  'client_notification',
  'change_with_refund',
  'change_with_surcharge',
  'change_bag_dishes',
  'bag_delivered',
  'cancel_bags',
  'menu_published',
  'menu_assistant_failed_suggestion',
  'mass_mail',
];

export const clientToggleable = ['change_bag_dishes', 'bag_delivered'];

export const mailingOptions = t => ({
  diet_reminder: t('mailNotification.mailingOptions.dietReminder'),
  new_account: t('mailNotification.mailingOptions.newAccount'),
  new_order: t('mailNotification.mailingOptions.newOrder'),
  new_order_v2: t('mailNotification.mailingOptions.newOrder') + ' - v2',
  change_with_surcharge: t(
    'mailNotification.mailingOptions.changeWithSurcharge'
  ),
  change_with_refund: t('mailNotification.mailingOptions.changeWithRefund'),
  order_status_change: t('mailNotification.mailingOptions.orderStatusChange'),
  waiting_order_status_change_paid: t(
    'mailNotification.mailingOptions.waitingOrderStatusChangePaid'
  ),
  waiting_order_status_change_canceled: t(
    'mailNotification.mailingOptions.waitingOrderStatusChangeCanceled'
  ),
  paid_order_status_change_canceled: t(
    'mailNotification.mailingOptions.paidOrderStatusChangeCanceled'
  ),
  remove_account: t('mailNotification.mailingOptions.removeAccount'),
  lost_password_admin: t('mailNotification.mailingOptions.lostPasswordAdmin'),
  lost_password: t('mailNotification.mailingOptions.lostPassword'),
  assign_zone_to_addresses_started: t(
    'mailNotification.mailingOptions.assignZoneToAddressStarted'
  ),
  assign_zone_to_addresses_finished: t(
    'mailNotification.mailingOptions.assignZoneToAddressFinished'
  ),
  subscription_renewed_order_successful: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderSuccessfull'
  ),
  subscription_renewed_order_failed: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderFailed'
  ),
  change_delivery: t('mailNotification.mailingOptions.changeDelivery'),
  change_bag_dishes: t('mailNotification.mailingOptions.changeDish'),
  order_verified: t('mailNotification.mailingOptions.orderVerified'),
  client_notification: t('mailNotification.mailingOptions.clientNotification'),
  cancel_bags: t('mailNotification.mailingOptions.cancelBags'),
  menu_published: t('mailNotification.mailingOptions.menuPublished'),
  client_marketing_terms: t(
    'mailNotification.mailingOptions.clientMarketingTerms'
  ),
  employee_welcome: t('mailNotification.mailingOptions.employeeWelcome'),
  bag_delivered: t('mailNotification.mailingOptions.bagDelivered'),
  import_diet_prices_finished: t(
    'mailNotification.mailingOptions.import_diet_prices_finished'
  ),
  import_diet_prices_started: t(
    'mailNotification.mailingOptions.import_diet_prices_started'
  ),
  menu_assistant_failed_suggestion: t(
    'mailNotification.mailingOptions.menu_assistant_failed_suggestion'
  ),
  mass_mail: t('mailNotification.mailingOptions.mass_mail'),
  daily_statistics: t('mailNotification.mailingOptions.daily_statistics'),
});
