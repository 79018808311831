import React from 'react';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { DialogLoader } from 'components/DialogLoader';
import HoverSettings from '../configClientPanel/HoverSettings';

import { ConfigClientPanelModule } from '../modules';
import SEOSettings from 'views/SEO/SEOSettings';
import BannerSettings from './components/BannerSettings';
import ApperanceSettings from './components/ApperanceSettings';
import OmnibusSettings from './components/OmnibusSettings';
import Caching from './components/Caching';

const OrderFormView = ({ props }) => {
  const {
    isLoading,
    modules,
    setModules,
    hoverState,
    setHoverState,
    state,
    setState,
    handleSubmit,
    handleInputChange,
    getImage,
    removeImage,
    handleToggle,
    classes,
    paymentTypes,
    t,
  } = props;

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {!isLoading && (
        <>
          <BannerSettings
            t={t}
            classes={classes}
            state={state}
            handleInputChange={handleInputChange}
            getImage={getImage}
            removeImage={removeImage}
            handleToggle={handleToggle}
          />

          <HoverSettings t={t} state={hoverState} setState={setHoverState} />

          <ApperanceSettings
            t={t}
            classes={classes}
            modules={modules}
            setModules={setModules}
          />

          <ConfigClientPanelModule
            classes={classes}
            handleModuleUpdate={module => {
              setModules(prevState => ({
                ...prevState,
                ConfigClientPanel: module,
              }));
            }}
            module={modules.ConfigClientPanel}
            state={state}
            setState={setState}
            paymentTypes={paymentTypes}
          />

          <OmnibusSettings
            t={t}
            classes={classes}
            modules={modules}
            setModules={setModules}
          />

          <Caching />

          <SEOSettings
            setState={data => setModules(prev => ({ ...prev, Seo: data }))}
            state={modules.Seo}
          />

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default OrderFormView;
