import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ResetPasswordInputAuth = ({
  label,
  state,
  setState,
  showStateKey,
  name,
  id,
  hasLowerCase,
  hasUpperCase,
  hasNumber,
  isValidLength,
}) => {
  const iconStyles = { color: '#BDBDBD', marginTop: '6px' };
  const specialCharPattern = /[@#$%]+/;
  const blockedCharPattern = /[\^&\*\(\)_\+\-=\[\]\{};':"\\|,.<>\/\?`~!]/;

  const validatePassword = (name, value) => {
    const newValues = {};
    if (name === 'newPassword') {
      newValues.hasLowerCase = hasLowerCase(value);
      newValues.hasUpperCase = hasUpperCase(value);
      newValues.hasNumber = hasNumber(value);
      newValues.isValidLength = isValidLength(value);
      newValues.identicalPasswords = value === state.repeatNewPassword;
      newValues.isUseSpecialChar =
        specialCharPattern.test(value) && !blockedCharPattern.test(value);
    } else {
      newValues.identicalPasswords = state.newPassword === value;
    }
    setState(prevState => ({
      ...prevState,
      passwordValidation: {
        ...prevState.passwordValidation,
        ...newValues,
      },
    }));
  };

  const comparePasswords = () => {
    if (
      state.newPassword &&
      state.repeatNewPassword &&
      !state.passwordValidation.identicalPasswords
    ) {
      setState({ showPasswordsNotMatchingMessage: true });
    } else {
      setState({ showPasswordsNotMatchingMessage: false });
    }
  };

  const handleChange = ev => {
    setState({
      [ev.target.name]: ev.target.value,
    });

    validatePassword(ev.target.name, ev.target.value);
  };

  return (
    <>
      <input
        type={state[showStateKey] ? 'text' : 'password'}
        className={`form__input ${state[name] ? 'label-top' : ''}`}
        autoComplete="off"
        name={name}
        id={id}
        placeholder=" "
        value={state[name]}
        onChange={handleChange}
        onBlur={comparePasswords}
      />
      <label className="form__label" htmlFor="password">
        {label}
      </label>
      <button
        type="button"
        className="toggle-password"
        onClick={() =>
          setState(prev => {
            return {
              ...prev,
              [showStateKey]: !prev[showStateKey],
            };
          })
        }
      >
        {state[showStateKey] ? (
          <VisibilityOutlined style={iconStyles} />
        ) : (
          <VisibilityOffOutlined style={iconStyles} />
        )}
      </button>
    </>
  );
};

export default ResetPasswordInputAuth;
