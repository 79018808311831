import { useTranslation } from 'react-i18next';
import { RoomService } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const CustomerService = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_USER) && {
      name: t('common.mainMenu.clients'),
      to: '/clients',
      additionalActivationPaths: getPathSuffixes('/clients', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted([roles.ROLE_SHOW_BASKET, roles.ROLE_SHOW_ORDER]) && {
      name: t('basket.menu'),
      to: '/baskets',
    },
    isGranted(roles.ROLE_SHOW_ORDER) && {
      name: t('common.mainMenu.orders'),
      to: '/orders',
      additionalActivationPaths: getPathSuffixes('/orders', ['add']),
    },
    isGranted(roles.ROLE_SHOW_ORDER) && {
      name: t('common.mainMenu.dietList'),
      to: '/ecommerce-diets',
      additionalActivationPaths: getPathSuffixes('/ecommerce-diets', [
        'preview/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_CLIENT_METRIC) && {
      name: t('common.mainMenu.clientsBmi'),
      to: '/clients-bmi',
    },
    isGranted(roles.ROLE_SHOW_BAG) && {
      name: t('ecommerceBags.name'),
      to: '/ecommerce-bags',
    },
    isGranted(roles.ROLE_SHOW_ORDER) && {
      name: t('common.mainMenu.subscriptions'),
      to: '/subscriptions',
    },
    isGranted(roles.ROLE_SHOW_RATE) && {
      name: t('common.mainMenu.clientComments'),
      to: '/rates',
    },
    isGranted(roles.ROLE_SHOW_MASS_SMS) && {
      name: t('common.mainMenu.sms'),
      to: '/mass-sms',
      additionalActivationPaths: getPathSuffixes('/mass-sms', ['add']),
    },
    isGranted(roles.ROLE_SHOW_MASS_MAIL) && {
      name: t('common.mainMenu.mail'),
      to: '/mass-mails',
      additionalActivationPaths: getPathSuffixes('/mass-mails', ['add']),
    },
    isGranted(roles.ROLE_SHOW_NOTIFICATION) && {
      name: t('common.mainMenu.messages'),
      to: '/messages',
      additionalActivationPaths: getPathSuffixes('/messages', [
        'add',
        'edit/:id',
      ]),
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.roomService')}
      icon={<RoomService />}
      links={links}
    />
  );
};

export default CustomerService;
